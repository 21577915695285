import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import CustomButton from "./customButton";

interface QualificationPopupProps {
  open: boolean;
  email: string;
  onClose: () => void;
  onSubmit: (data: QualificationData) => void;
}

export interface QualificationData {
  email: string;
  channelName: string | null;
  subscriberCount: string;
  referralSource: string;
}

const subscriberRanges = [
  { value: "1-1000", label: "1-1,000" },
  { value: "1001-10000", label: "1,001-10,000" },
  { value: "10001-100000", label: "10,001-100,000" },
  { value: "100001+", label: "100,001+" },
];

const referralSources = [
  { value: "online_search", label: "Online Search" },
  { value: "social_media", label: "Social Media" },
  { value: "friends_family", label: "Friends & Family" },
  { value: "influencer", label: "Influencer" },
  { value: "news", label: "News" },
  { value: "yougenie_blog", label: "YouGenie Blog" },
  { value: "other_blog", label: "Other Blog" },
  { value: "other", label: "Other Source" },
];

export default function QualificationPopup({
  open,
  email,
  onClose,
  onSubmit,
}: QualificationPopupProps) {
  const [channelName, setChannelName] = useState<string>("");
  const [hasNoChannel, setHasNoChannel] = useState(false);
  const [subscriberCount, setSubscriberCount] = useState("");
  const [referralSource, setReferralSource] = useState("");

  const handleSubmit = () => {
    if (!hasNoChannel && !channelName) return;
    if (!hasNoChannel && !subscriberCount) return;
    if (!referralSource) return;

    onSubmit({
      email,
      channelName: hasNoChannel ? null : channelName,
      subscriberCount: hasNoChannel ? "0" : subscriberCount,
      referralSource,
    });
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: "#0B0B0F",
          color: "white",
          fontFamily: "'YouTubeSansRegular', sans-serif",
          maxWidth: "600px",
          width: "100%",
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontFamily: "'YouTubeSansBold', sans-serif",
          paddingBottom: 1,
        }}
      >
        Answer and win 20 credits! 🎉
      </DialogTitle>
      <DialogContent>
        <div className="space-y-8 py-4">
          <div className="space-y-4">
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setHasNoChannel(!hasNoChannel);
                  if (!hasNoChannel) {
                    setChannelName("");
                    setSubscriberCount("");
                  }
                }}
                className="text-sm text-gray-400 hover:text-white transition-colors font-custom"
              >
                {hasNoChannel
                  ? "I have a channel"
                  : "I don't have a channel yet"}
              </button>
            </div>

            {!hasNoChannel && (
              <div className="space-y-6">
                <TextField
                  fullWidth
                  label="Channel Name"
                  value={channelName}
                  onChange={(e) => setChannelName(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      fontFamily: "'YouTubeSansRegular', sans-serif",
                      "& fieldset": { borderColor: "#282828" },
                      "&:hover fieldset": { borderColor: "#383838" },
                    },
                    "& .MuiInputLabel-root": {
                      color: "gray",
                      fontFamily: "'YouTubeSansRegular', sans-serif",
                    },
                  }}
                />

                <div className="space-y-2">
                  <div className="text-gray-400 text-sm mb-2 font-custom">
                    Subscriber Count
                  </div>
                  <RadioGroup
                    value={subscriberCount}
                    onChange={(e) => setSubscriberCount(e.target.value)}
                    row
                  >
                    <div className="flex flex-wrap gap-4">
                      {subscriberRanges.map((range) => (
                        <FormControlLabel
                          key={range.value}
                          value={range.value}
                          control={
                            <Radio
                              sx={{
                                color: "gray",
                                "&.Mui-checked": { color: "#FF0000" },
                              }}
                            />
                          }
                          label={range.label}
                          sx={{
                            color: "white",
                            "& .MuiFormControlLabel-label": {
                              fontSize: "0.875rem",
                              fontFamily: "'YouTubeSansRegular', sans-serif",
                            },
                          }}
                        />
                      ))}
                    </div>
                  </RadioGroup>
                </div>
              </div>
            )}

            <div className="space-y-2 mt-6">
              <div className="text-gray-400 text-sm mb-2 font-custom">
                How did you find us?
              </div>
              <RadioGroup
                value={referralSource}
                onChange={(e) => setReferralSource(e.target.value)}
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                  {referralSources.map((source) => (
                    <FormControlLabel
                      key={source.value}
                      value={source.value}
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "#FF0000" },
                          }}
                        />
                      }
                      label={source.label}
                      sx={{
                        color: "white",
                        "& .MuiFormControlLabel-label": {
                          fontSize: "0.875rem",
                          fontFamily: "'YouTubeSansRegular', sans-serif",
                        },
                      }}
                    />
                  ))}
                </div>
              </RadioGroup>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <CustomButton
          onClick={handleSubmit}
          disabled={
            (!hasNoChannel && (!channelName || !subscriberCount)) ||
            !referralSource
          }
        >
          Continue
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}
