import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { IconX } from "@tabler/icons-react";

interface OnboardingDialogProps {
  open: boolean;
  onClose: () => void;
}

const OnboardingDialog: React.FC<OnboardingDialogProps> = ({
  open,
  onClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: "#0B0B0F",
          borderRadius: "12px",
        },
      }}
    >
      <DialogContent sx={{ padding: 0, position: "relative" }}>
        <button
          onClick={onClose}
          className="absolute top-2 right-2 z-10 text-white hover:text-gray-300 bg-black bg-opacity-50 rounded-full p-1"
        >
          <IconX size={24} />
        </button>
        <div
          className="relative"
          style={{
            paddingBottom: "56.25%",
            overflow: "hidden",
          }}
        >
          <iframe
            className="absolute top-0 left-0 w-full h-full"
            src="https://www.youtube.com/embed/saXS8UFxaJE?autoplay=1&mute=0&controls=1&rel=0&modestbranding=1"
            title="YouGenie Onboarding Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default OnboardingDialog;
