import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import CustomButton from "../utils/customButton";
import Functions from "../../callable/functions";
import TranscriptionDb from "../../db/transcriptionDb";
import { onSnapshot } from "firebase/firestore";
import {
  IconBrandYoutube,
  IconHelp,
  IconBubbleText,
  IconChevronDown,
} from "@tabler/icons-react";
import { CircularProgress } from "@mui/material";
import ReactMarkdown from "react-markdown";

export default function TranscriptionTool() {
  const [videoUrl, setVideoUrl] = useState("");
  const [transcription, setTranscription] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [currentVideoId, setCurrentVideoId] = useState<string | null>(null);

  const pageTitle =
    "YouTube Video Transcription | Free AI-Powered Transcription Tool";
  const pageDescription =
    "Generate accurate transcriptions of your YouTube videos with our free AI-powered tool. Get searchable text content from your videos to improve accessibility and SEO.";

  // Set up Firestore listener when videoId changes
  useEffect(() => {
    if (!currentVideoId) return;

    const unsubscribe = onSnapshot(
      new TranscriptionDb().doc({ videoId: currentVideoId }),
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          if (data.status === "completed" && data.text) {
            setTranscription(data.text);
            setProcessing(false);
          } else if (data.status === "failed") {
            setError("Transcription failed. Please try again.");
            setProcessing(false);
          }
        }
      }
    );

    return () => unsubscribe();
  }, [currentVideoId]);

  const extractVideoId = (url: string) => {
    const regex =
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const handleTranscribe = async () => {
    const videoId = extractVideoId(videoUrl);

    if (!videoId) {
      setError("Please enter a valid YouTube video URL");
      return;
    }

    setProcessing(true);
    setError(null);
    setCurrentVideoId(videoId);

    try {
      const functions = new Functions();
      const result = await functions.startTranscription({
        videoId: videoId,
        channelId: "any",
      });

      if (result.data.status === "exists" && result.data.text) {
        setTranscription(result.data.text);
        setProcessing(false);
      } else if (result.data.status === "pending") {
        // Don't set processing to false here - wait for the listener to update
        setTranscription(
          "Processing... Please wait while we transcribe your video."
        );
      } else {
        setError("Failed to start transcription. Please try again.");
        setProcessing(false);
      }
    } catch (error) {
      console.error("Error transcribing video:", error);
      setError("An error occurred while transcribing the video");
      setProcessing(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>

      <div className="min-h-screen bg-[#0B0B0F] text-white font-custom">
        <header className="w-full max-w-5xl flex justify-between items-center px-4 py-4 mx-auto">
          <a href="/">
            <img
              src="/images/logo.svg"
              alt="YouGenie Logo"
              className="h-6 sm:h-8 cursor-pointer"
            />
          </a>
        </header>

        <main className="max-w-5xl mx-auto px-4 py-8">
          <h1 className="text-2xl sm:text-4xl font-bold mb-4">
            YouTube Video Transcription
          </h1>
          <p className="text-gray-400 mb-8">
            Convert your YouTube videos into text with our AI-powered
            transcription tool. Perfect for creating subtitles, improving
            accessibility, or repurposing content.
          </p>

          {/* How It Works Section */}
          <div className="mb-12">
            <h2 className="text-xl sm:text-2xl font-bold mb-4">How It Works</h2>
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6">
              {[
                {
                  step: 1,
                  title: "Paste Video URL",
                  description:
                    "Enter the YouTube video URL you want to transcribe.",
                },
                {
                  step: 2,
                  title: "Start Transcription",
                  description:
                    "Click the button to begin the AI-powered transcription process.",
                },
                {
                  step: 3,
                  title: "Processing",
                  description:
                    "Our AI analyzes the video audio and converts it to text.",
                },
                {
                  step: 4,
                  title: "Get Results",
                  description:
                    "Receive your accurate transcription ready for use.",
                },
              ].map((step) => (
                <div key={step.step} className="bg-[#1A1A1F] p-6 rounded-lg">
                  <div className="flex items-center justify-center w-12 h-12 bg-red-500 rounded-full mb-4">
                    <span className="text-xl font-bold">{step.step}</span>
                  </div>
                  <h3 className="font-bold mb-2">{step.title}</h3>
                  <p className="text-gray-400 text-sm">{step.description}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Main Transcription Section */}
          <div className="space-y-6 mb-12">
            <div className="w-full space-y-6">
              <div>
                <label className="block text-sm font-bold mb-2">
                  YouTube Video URL
                </label>
                <input
                  type="text"
                  value={videoUrl}
                  onChange={(e) => setVideoUrl(e.target.value)}
                  placeholder="https://www.youtube.com/watch?v=..."
                  className="w-full bg-[#1A1A1F] border border-gray-700 rounded-lg p-3 text-white placeholder-gray-500 focus:outline-none focus:border-red-500"
                />
              </div>

              {error && <div className="text-red-500 text-sm">{error}</div>}

              <div className="flex justify-end">
                <CustomButton
                  onClick={handleTranscribe}
                  disabled={processing || !videoUrl.trim()}
                  startIcon={
                    processing ? (
                      <CircularProgress size={20} />
                    ) : (
                      <IconBubbleText size={20} />
                    )
                  }
                >
                  {processing ? "Transcribing..." : "Start Transcription"}
                </CustomButton>
              </div>
            </div>

            {/* Results Section */}
            <div className="w-full">
              <div className="bg-[#1A1A1F] rounded-lg p-6">
                <h2 className="text-xl font-bold mb-4">
                  Transcription Results
                </h2>
                {processing ? (
                  <div className="flex flex-col items-center justify-center space-y-4 py-12">
                    <CircularProgress color="inherit" size={40} />
                    <p className="text-gray-400">Transcribing your video...</p>
                  </div>
                ) : transcription ? (
                  <div className="prose prose-invert max-w-none">
                    <ReactMarkdown className="text-gray-300 whitespace-pre-wrap">
                      {transcription}
                    </ReactMarkdown>
                  </div>
                ) : (
                  <div className="text-center py-12 text-gray-400">
                    <IconBrandYoutube
                      size={48}
                      className="mx-auto mb-4 text-gray-500"
                    />
                    <p>Enter a YouTube video URL to get started</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* FAQ Section */}
          <section className="mt-12 sm:mt-16 w-full mx-auto mb-12">
            <div className="flex items-center gap-3 mb-6 sm:mb-8">
              <IconHelp size={24} className="text-red-500" stroke={1.5} />
              <h2 className="text-xl sm:text-3xl font-bold font-custom">
                Frequently Asked Questions
              </h2>
            </div>
            <div className="space-y-4">
              {[
                {
                  question: "How accurate is the transcription?",
                  answer:
                    "Our AI-powered transcription provides high accuracy for clear audio, typically achieving 90%+ accuracy for well-recorded videos.",
                },
                {
                  question: "How long does transcription take?",
                  answer:
                    "Processing time depends on video length. Most videos are transcribed within minutes.",
                },
                {
                  question: "What languages are supported?",
                  answer:
                    "The tool currently supports English, with more languages coming soon.",
                },
                {
                  question: "Is there a video length limit?",
                  answer:
                    "Yes, videos up to 2 hours can be transcribed for free.",
                },
                {
                  question: "Can I edit the transcription?",
                  answer:
                    "Yes, you can copy and edit the transcription text as needed.",
                },
                {
                  question: "Is the tool free to use?",
                  answer:
                    "Yes, the YouTube Video Transcription tool is completely free.",
                },
              ].map((faq, index) => (
                <div
                  key={index}
                  className="bg-[#1A1A1F] rounded-lg overflow-hidden"
                >
                  <button
                    className="w-full p-4 flex justify-between items-center text-left"
                    onClick={() =>
                      setOpenIndex(openIndex === index ? null : index)
                    }
                  >
                    <h3 className="text-lg font-medium">{faq.question}</h3>
                    <IconChevronDown
                      size={20}
                      className={`transform transition-transform duration-300 ${
                        openIndex === index ? "rotate-180" : ""
                      }`}
                    />
                  </button>
                  <div
                    className={`overflow-hidden transition-all duration-300 ${
                      openIndex === index ? "max-h-48" : "max-h-0"
                    }`}
                  >
                    <p className="text-gray-400 p-4 pt-0 text-left">
                      {faq.answer}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </main>
      </div>
    </>
  );
}
