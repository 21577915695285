import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import theme from "./theme";
import { ThemeProvider, CssBaseline } from "@mui/material";

import LandingPage from "./components/pages/landing";
import ThumbGenie from "./components/pages/thumbGenie";
import ThumbGenieApp from "./components/screens/thumb-genie";
import PrivacyPolicy from "./components/pages/privacyPolicy";
import CampaignGenie from "./components/pages/campaignGenie";
import CampaignGenieApp from "./components/screens/campaign-genie";
import InsightGenieApp from "./components/screens/insight-genie";
import InsightGenie from "./components/pages/insightGenie";
import ThumbnailEditor from "./components/pages/thumbnailEditor";
import ScriptAnalyzer from "./components/pages/scriptAnalyzer";
import Tools from "./components/pages/tools";
import YoutubePreview from "./components/pages/youtubePreview";
import TitleGenerator from "./components/pages/titleGenerator";
import TranscriptionTool from "./components/pages/transcriptionTool";

export default function App() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/thumb-genie" element={<ThumbGenie />} />
            <Route path="/campaign-genie" element={<CampaignGenie />} />
            <Route path="/insight-genie" element={<InsightGenie />} />
            <Route path="/app/thumb-genie/*" element={<ThumbGenieApp />} />
            <Route
              path="/app/campaign-genie/*"
              element={<CampaignGenieApp />}
            />
            <Route path="/app/insight-genie/*" element={<InsightGenieApp />} />
            <Route path="/thumbnail-editor" element={<ThumbnailEditor />} />
            <Route path="/script-analyzer" element={<ScriptAnalyzer />} />
            <Route path="/youtube-preview" element={<YoutubePreview />} />
            <Route path="/title-generator" element={<TitleGenerator />} />
            <Route
              path="/youtube-transcription"
              element={<TranscriptionTool />}
            />
            <Route path="/tools" element={<Tools />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
}
