import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconLogout,
  IconCpu,
  IconPhotoBolt,
  IconCoins,
  IconPhotoEdit,
} from "@tabler/icons-react";
import { getAuth, signOut } from "firebase/auth";
import { UserContext } from "../../../contexts/userContext";
import { Tooltip, Menu, MenuItem } from "@mui/material";

export default function SideBar() {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleLogoHover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogoLeave = () => {
    setAnchorEl(null);
  };

  const handleProductSwitch = (product: string) => {
    handleLogoLeave();
    navigate(`/app/${product}`);
  };

  const handleHome = () => {
    navigate("/app/thumb-genie");
  };

  const handleModels = () => {
    navigate("/app/thumb-genie/models");
  };

  const handleCredits = () => {
    navigate("/app/thumb-genie/credits");
  };

  const handleLogout = () => {
    signOut(getAuth());
    navigate("/thumb-genie");
  };

  return (
    <div className="bg-[#0B0B0F] h-screen w-20 flex flex-col items-center py-6 space-y-6 border-r border-[#282828] fixed top-0 left-0">
      <Tooltip title="ThumbGenie by YouGenie" placement="right">
        <div
          className="relative"
          onMouseEnter={handleLogoHover}
          onMouseLeave={handleLogoLeave}
        >
          <img
            src="/images/logo.svg"
            alt="YouGenie Logo"
            className="w-8 h-8 mb-4 cursor-pointer"
            onClick={handleHome}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleLogoLeave}
            PaperProps={{
              style: {
                backgroundColor: "#1E1E1E",
                color: "white",
              },
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={() => handleProductSwitch("campaign-genie")}>
              CampaignGenie
            </MenuItem>
            <MenuItem onClick={() => handleProductSwitch("insight-genie")}>
              InsightGenie
            </MenuItem>
          </Menu>
        </div>
      </Tooltip>
      <Tooltip title="Thumbnail Generator" placement="right">
        <button onClick={handleHome}>
          <IconPhotoBolt
            size={24}
            strokeWidth={1}
            className="text-[#FFFFFF] mb-6"
          />
        </button>
      </Tooltip>
      <Tooltip title="Model Trainings" placement="right">
        <button onClick={handleModels}>
          <IconCpu size={24} strokeWidth={1} className="text-[#FFFFFF] mb-6" />
        </button>
      </Tooltip>
      <div className="flex-grow" />
      <Tooltip
        title={
          <div className="p-1">
            <p className="text-sm">
              {(user?.credits || 0).toLocaleString()} credits
            </p>
          </div>
        }
        placement="right"
      >
        <button onClick={handleCredits}>
          <div className="relative">
            <IconCoins
              size={24}
              strokeWidth={1}
              className="text-[#FFFFFF] mb-6"
            />
            <span className="absolute -top-2 -right-2 bg-red-500 text-white text-[10px] rounded-full min-w-[16px] h-4 flex items-center justify-center px-1">
              {user?.credits && user.credits > 99 ? "99+" : user?.credits || 0}
            </span>
          </div>
        </button>
      </Tooltip>
      <Tooltip title="Logout" placement="right">
        <button onClick={handleLogout}>
          <IconLogout size={24} strokeWidth={1} className="text-[#FFFFFF]" />
        </button>
      </Tooltip>
    </div>
  );
}
