import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  updateOnboardingStatus,
  UserContext,
} from "../../../contexts/userContext";
import {
  Stepper,
  Step,
  StepLabel,
  TextField,
  Divider,
  ButtonGroup,
  Button,
  Select,
  MenuItem,
  Grid,
  Tooltip,
  IconButton,
} from "@mui/material";
import CustomButton from "../../utils/customButton";
import {
  IconBolt,
  IconCpu,
  IconWand,
  IconPencil,
  IconLink,
  IconRefresh,
  IconPhoto,
  IconChevronUp,
  IconChevronDown,
  IconEdit,
  IconThumbUp,
  IconThumbDown,
  IconTrash,
} from "@tabler/icons-react";
import { Model } from "../../../models/model";
import ModelDb from "../../../db/modelDb";
import {
  getDocs,
  query,
  orderBy,
  where,
  limit,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { useDropzone } from "react-dropzone";
import { InputAdornment, CircularProgress } from "@mui/material";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import Functions from "../../../callable/functions";
import { Generation } from "../../../models/generation";
import GenerationDb from "../../../db/generationDb";
import CustomAlert from "../../utils/customAlert";
import ImageEditModal from "./imageEditModal";
import FeedbackDialog from "./feedbackDialog";
import OnboardingDialog from "../../utils/onboardingDialog";

interface GenerationConfig {
  userId: string;
  modelIdentifier: string;
  prompt: string;
  image?: string;
  guidanceScale?: number;
}

const PipelineScreen: React.FC = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const [activeStep, setActiveStep] = useState(0);
  const [useBaseModel, setUseBaseModel] = useState<boolean>(true);
  const [prompt, setPrompt] = useState<string>("");
  const [selectedCustomModel, setSelectedCustomModel] = useState<string>("");
  const [isAdvancedPrompt, setIsAdvancedPrompt] = useState(false);
  const [basicPrompt, setBasicPrompt] = useState({
    subject: "",
    action: "",
    style: "",
    background: "",
    effects: "",
    text: "", // Add this new field
  });
  const [models, setModels] = useState<Model[]>([]);
  const [youtubeLink, setYoutubeLink] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [uploadingImage, setUploadingImage] = useState<boolean>(false);
  const [realismScale, setRealismScale] = useState<number>(3.5);
  const [generationConfig, setGenerationConfig] = useState<GenerationConfig>({
    userId: user?.id || "",
    modelIdentifier: "",
    prompt: "",
  });
  const [generating, setGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [generations, setGenerations] = useState<Generation[]>([]);
  const [generatedThumbnails, setGeneratedThumbnails] = useState<string[]>([]);
  const [allGenerations, setAllGenerations] = useState<Generation[]>([]);
  const [isBottomBarVisible, setIsBottomBarVisible] = useState(true);
  const [useReferenceImage, setUseReferenceImage] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedEditImage, setSelectedEditImage] = useState<string | null>(
    null
  );
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [selectedGenerationId, setSelectedGenerationId] = useState<
    string | null
  >(null);
  const [skipPromptEnhancement, setSkipPromptEnhancement] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);

  useEffect(() => {
    const fetchModels = async () => {
      if (user) {
        try {
          const modelDb = new ModelDb();
          const modelsQuery = query(
            modelDb.collection({ userId: user.id, modelId: "" }),
            orderBy("createdAt", "desc")
          );
          const querySnapshot = await getDocs(modelsQuery);
          const modelsData = querySnapshot.docs.map((doc) =>
            Model.fromFirestore(doc)
          );
          setModels(modelsData);
        } catch (error) {
          console.error("Error fetching models:", error);
        }
      }
    };

    fetchModels();
  }, [user]);

  useEffect(() => {
    if (user && activeStep === 1 && !user.hasOnboarded) {
      setShowOnboarding(true);
    }
  }, [user, activeStep]);

  const handleOnboardingClose = async () => {
    if (user) {
      await updateOnboardingStatus(user.id);
      setShowOnboarding(false);
    }
  };

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        setGenerationConfig((prev) => ({
          ...prev,
          modelIdentifier: useBaseModel
            ? "black-forest-labs/flux-1.1-pro"
            : models.find((m) => m.id === selectedCustomModel)?.identifier ||
              "",
        }));
        break;
      case 1:
        setGenerationConfig((prev) => ({
          ...prev,
          image: selectedImage || undefined,
        }));
        break;
      case 2:
        setGenerationConfig((prev) => ({
          ...prev,
          prompt: isAdvancedPrompt ? prompt : formatBasicPrompt(basicPrompt),
        }));
        break;
      case 3:
        setGenerationConfig((prev) => ({
          ...prev,
          guidanceScale: useBaseModel ? undefined : realismScale,
        }));
        handleGenerate();
        return;
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const steps = ["Model", "Reference", "Prompt", "Realism", "Thumbnails"];

  const handleFileSelect = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      if (file.size > 5242880) {
        alert("File is too large. Maximum size is 5MB.");
        return;
      }

      setUploadingImage(true);
      try {
        const img = new Image();
        img.onload = async () => {
          const aspectRatio = img.width / img.height;
          if (Math.abs(aspectRatio - 16 / 9) < 0.01) {
            const storage = getStorage();
            const storageRef = ref(
              storage,
              `users/${user?.id}/reference-images/${Date.now()}`
            );
            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);
            setSelectedImage(downloadURL);
            setYoutubeLink("");
          } else {
            alert("Please upload an image with 16:9 aspect ratio only.");
          }
          setUploadingImage(false);
        };
        img.src = URL.createObjectURL(file);
      } catch (error) {
        console.error("Error uploading image:", error);
        alert("Failed to upload image. Please try again.");
        setUploadingImage(false);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileSelect,
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: false,
    maxSize: 5242880,
  });

  const handleYoutubeLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    setYoutubeLink(url);
    const videoId = extractYoutubeVideoId(url);
    if (videoId) {
      const thumbnailUrl = `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;
      setSelectedImage(thumbnailUrl);
    }
  };

  const extractYoutubeVideoId = (url: string): string | null => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  const getModeLabel = (scale: number) => {
    switch (scale) {
      case 2:
        return "Realistic";
      case 2.5:
        return "Balanced Realistic";
      case 3:
        return "Balanced Creative";
      case 3.5:
        return "Highly Creative";
      default:
        return "Unknown";
    }
  };

  const getStepDescription = (step: number) => {
    switch (step) {
      case 0:
        return "Choose between our base model for quick results or your custom trained model for personalized thumbnails.";
      case 1:
        return "(Optional) Add a reference image to help guide the AI in matching your desired style and composition.";
      case 2:
        return "Tell us what you want in your thumbnail. You can use basic mode for guided input or advanced mode for complete control.";
      case 3:
        return "Control how closely the AI should follow your prompt versus taking creative liberties.";
      case 4:
        return "View your generated thumbnails and previous generations.";
      default:
        return "";
    }
  };

  const renderStepContent = (step: number) => {
    return (
      <div className="space-y-6 mt-8">
        <p className="text-gray-400 text-sm leading-relaxed">
          {getStepDescription(step)}
        </p>

        {renderStepSpecificContent(step)}
      </div>
    );
  };

  const renderStepSpecificContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <div className="space-y-6">
            <div className="grid grid-cols-1 gap-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div
                  className={`p-8 rounded-xl cursor-pointer transition-all duration-300 hover:bg-[#1A1A1F] h-[240px] ${
                    useBaseModel
                      ? "bg-[#1A1A1F] ring-2 ring-red-500"
                      : "bg-[#141419]"
                  }`}
                  onClick={() => {
                    setUseBaseModel(true);
                    handleNext();
                  }}
                >
                  <div className="flex flex-col h-full">
                    <div className="p-3 bg-red-500/10 rounded-lg w-fit">
                      <IconBolt size={24} className="text-red-500" />
                    </div>
                    <div className="flex-grow mt-6">
                      <h3 className="text-xl font-bold text-white mb-3">
                        Quick Start
                      </h3>
                      <p className="text-gray-400 text-sm leading-relaxed">
                        Use our base model for quick thumbnail generation.
                        Perfect for getting started and exploring the
                        possibilities.
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className={`p-8 rounded-xl cursor-pointer transition-all duration-300 hover:bg-[#1A1A1F] h-[240px] ${
                    !useBaseModel
                      ? "bg-[#1A1A1F] ring-2 ring-red-500"
                      : "bg-[#141419]"
                  }`}
                  onClick={() => setUseBaseModel(false)}
                >
                  <div className="flex flex-col h-full">
                    <div className="p-3 bg-red-500/10 rounded-lg w-fit">
                      <IconCpu size={24} className="text-red-500" />
                    </div>
                    <div className="flex-grow mt-6">
                      <h3 className="text-xl font-bold text-white mb-3">
                        Custom Model
                      </h3>
                      <p className="text-gray-400 text-sm leading-relaxed">
                        Use your trained model for personalized thumbnails
                        matching your channel's unique style.{" "}
                        <span
                          className="text-red-500 hover:text-red-400 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate("/app/thumb-genie/models");
                          }}
                        >
                          Train a new model →
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {!useBaseModel && (
                <div className="bg-[#141419] p-6 rounded-xl w-full">
                  <div className="flex justify-between items-center mb-4">
                    <p className="text-sm text-gray-400">
                      Select a trained model to use
                    </p>
                    {models.length === 0 && (
                      <p className="text-sm text-red-500">
                        No models available. Train a model first.
                      </p>
                    )}
                  </div>
                  <Select
                    fullWidth
                    value={selectedCustomModel}
                    onChange={(e) => setSelectedCustomModel(e.target.value)}
                    displayEmpty
                    sx={{
                      color: "white",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#282828",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#484848",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FF0000",
                      },
                      "& .MuiSelect-icon": {
                        color: "white",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          backgroundColor: "#1E1E1E",
                          color: "white",
                          borderRadius: "8px",
                        },
                      },
                    }}
                  >
                    <MenuItem
                      value=""
                      disabled
                      style={{ color: "rgba(255, 255, 255, 0.7)" }}
                    >
                      Select a model
                    </MenuItem>
                    {models.map((model) => (
                      <MenuItem
                        key={model.id}
                        value={model.id}
                        sx={{
                          borderBottom: "1px solid #282828",
                          "&:last-child": {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <div>
                          <div className="font-bold">{model.name}</div>
                          <div className="text-sm text-gray-400">
                            {model.description}
                          </div>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}

              <div className="flex justify-between mt-4">
                <div /> {/* Empty div for spacing */}
                <CustomButton
                  onClick={handleNext}
                  disabled={
                    !useBaseModel &&
                    (!selectedCustomModel || models.length === 0)
                  }
                >
                  Next
                </CustomButton>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div
                className={`p-8 rounded-xl cursor-pointer transition-all duration-300 hover:bg-[#1A1A1F] h-[240px] ${
                  !useReferenceImage
                    ? "bg-[#1A1A1F] ring-2 ring-red-500"
                    : "bg-[#141419]"
                }`}
                onClick={() => {
                  setUseReferenceImage(false);
                  setSelectedImage(null);
                  setYoutubeLink("");
                  handleNext();
                }}
              >
                <div className="flex flex-col h-full">
                  <div className="p-3 bg-red-500/10 rounded-lg w-fit">
                    <IconBolt size={24} className="text-red-500" />
                  </div>
                  <div className="flex-grow mt-6">
                    <h3 className="text-xl font-bold text-white mb-3">
                      No Reference
                    </h3>
                    <p className="text-gray-400 text-sm leading-relaxed">
                      Generate thumbnails without a reference image. Let the AI
                      have complete creative freedom.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className={`p-8 rounded-xl cursor-pointer transition-all duration-300 hover:bg-[#1A1A1F] h-[240px] ${
                  useReferenceImage
                    ? "bg-[#1A1A1F] ring-2 ring-red-500"
                    : "bg-[#141419]"
                }`}
                onClick={() => setUseReferenceImage(true)}
              >
                <div className="flex flex-col h-full">
                  <div className="p-3 bg-red-500/10 rounded-lg w-fit">
                    <IconPhoto size={24} className="text-red-500" />
                  </div>
                  <div className="flex-grow mt-6">
                    <h3 className="text-xl font-bold text-white mb-3">
                      Use Reference
                    </h3>
                    <p className="text-gray-400 text-sm leading-relaxed">
                      Upload or link a reference image to guide the AI in
                      matching your desired style and composition.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {useReferenceImage && (
              <div className="bg-[#141419] p-6 rounded-xl w-full">
                <p className="text-gray-400 mb-4">
                  Upload a reference image to guide the AI in generating your
                  thumbnail.
                </p>

                <div className="flex flex-col space-y-4">
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Paste YouTube video URL for thumbnail reference"
                    value={youtubeLink}
                    onChange={handleYoutubeLinkChange}
                    InputProps={{
                      style: { color: "white" },
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconLink size={20} className="text-gray-400" />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "#282828" },
                        "&:hover fieldset": { borderColor: "#484848" },
                        "&.Mui-focused fieldset": { borderColor: "#FF0000" },
                      },
                    }}
                  />

                  <div
                    {...getRootProps()}
                    className={`border-2 border-dashed border-[#282828] rounded-lg p-4 text-center cursor-pointer ${
                      isDragActive ? "bg-gray-700" : ""
                    }`}
                  >
                    <input {...getInputProps()} />
                    {uploadingImage ? (
                      <div className="flex items-center justify-center space-x-2">
                        <CircularProgress size={20} sx={{ color: "#FF0000" }} />
                        <p className="text-sm text-gray-400">
                          Uploading image...
                        </p>
                      </div>
                    ) : isDragActive ? (
                      <p className="text-sm text-gray-400">
                        Drop the image here ...
                      </p>
                    ) : (
                      <p className="text-sm text-gray-400">
                        Drag & drop an image here, or click to select (16:9
                        ratio required)
                      </p>
                    )}
                  </div>

                  {selectedImage && (
                    <div className="flex items-center space-x-2">
                      <img
                        src={selectedImage}
                        alt="Selected reference"
                        className="w-32 h-18 object-cover rounded"
                      />
                      <IconRefresh
                        className="cursor-pointer text-gray-400 hover:text-white"
                        onClick={() => {
                          setSelectedImage(null);
                          setYoutubeLink("");
                        }}
                        size={20}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="flex justify-between mt-4">
              <CustomButton onClick={handleBack}>Back</CustomButton>
              <CustomButton onClick={handleNext}>Next</CustomButton>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div
                className={`p-6 rounded-xl cursor-pointer transition-all duration-300 hover:bg-[#1A1A1F] ${
                  !isAdvancedPrompt
                    ? "bg-[#1A1A1F] ring-2 ring-red-500"
                    : "bg-[#141419]"
                }`}
                onClick={() => setIsAdvancedPrompt(false)}
              >
                <div className="flex items-start space-x-4">
                  <div className="p-3 bg-red-500/10 rounded-lg">
                    <IconWand size={24} className="text-red-500" />
                  </div>
                  <div className="flex-grow">
                    <h3 className="text-lg font-bold text-white mb-2">
                      Basic Mode
                    </h3>
                    <p className="text-gray-400 text-sm">
                      We'll help you create the perfect prompt by asking simple
                      questions.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className={`p-6 rounded-xl cursor-pointer transition-all duration-300 hover:bg-[#1A1A1F] ${
                  isAdvancedPrompt
                    ? "bg-[#1A1A1F] ring-2 ring-red-500"
                    : "bg-[#141419]"
                }`}
                onClick={() => setIsAdvancedPrompt(true)}
              >
                <div className="flex items-start space-x-4">
                  <div className="p-3 bg-red-500/10 rounded-lg">
                    <IconPencil size={24} className="text-red-500" />
                  </div>
                  <div className="flex-grow">
                    <h3 className="text-lg font-bold text-white mb-2">
                      Advanced Mode
                    </h3>
                    <p className="text-gray-400 text-sm">
                      Write your own custom prompt for complete control over the
                      generation.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 bg-[#141419] p-6 rounded-xl">
              {isAdvancedPrompt ? (
                <div className="space-y-4">
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Prompt"
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    multiline
                    rows={4}
                    placeholder={
                      models.find((m) => m.id === selectedCustomModel)
                        ?.basePrompt ||
                      "Create a dramatic scene of Noah's Ark on a hilltop under stormy gray skies. Show pairs of animals walking toward the massive wooden ark. Place Noah in the foreground with golden sunlight breaking through the clouds behind him. Add a forming rainbow in the sky. Include bold, centered text reading 'Noah's Ark' in a striking font that stands out against the background."
                    }
                    InputProps={{
                      style: { color: "white" },
                    }}
                    InputLabelProps={{
                      style: { color: "rgba(255, 255, 255, 0.7)" },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#282828",
                        },
                        "&:hover fieldset": {
                          borderColor: "#484848",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#FF0000",
                        },
                      },
                    }}
                  />
                  <div className="flex items-center justify-end space-x-2">
                    <input
                      type="checkbox"
                      id="skipPromptEnhancement"
                      checked={skipPromptEnhancement}
                      onChange={(e) =>
                        setSkipPromptEnhancement(e.target.checked)
                      }
                      className="w-4 h-4 rounded border-gray-300 text-red-500 focus:ring-red-500"
                    />
                    <label
                      htmlFor="skipPromptEnhancement"
                      className="text-gray-400 text-sm"
                    >
                      Prompt Enhancement
                    </label>
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="bg-[#1A1A1F] p-6 rounded-xl space-y-4">
                    <h4 className="text-white font-bold mb-4">Main Elements</h4>
                    <TextField
                      fullWidth
                      label="Main Subject"
                      value={basicPrompt.subject}
                      onChange={(e) =>
                        setBasicPrompt({
                          ...basicPrompt,
                          subject: e.target.value,
                        })
                      }
                      placeholder="What's the main focus?"
                    />
                    <TextField
                      fullWidth
                      label="Action/Emotion"
                      value={basicPrompt.action}
                      onChange={(e) =>
                        setBasicPrompt({
                          ...basicPrompt,
                          action: e.target.value,
                        })
                      }
                      placeholder="What's happening?"
                    />
                  </div>

                  <div className="bg-[#1A1A1F] p-6 rounded-xl space-y-4">
                    <h4 className="text-white font-bold mb-4">
                      Style & Atmosphere
                    </h4>
                    <TextField
                      fullWidth
                      label="Visual Style"
                      value={basicPrompt.style}
                      onChange={(e) =>
                        setBasicPrompt({
                          ...basicPrompt,
                          style: e.target.value,
                        })
                      }
                      placeholder="How should it look?"
                    />
                    <TextField
                      fullWidth
                      label="Background"
                      value={basicPrompt.background}
                      onChange={(e) =>
                        setBasicPrompt({
                          ...basicPrompt,
                          background: e.target.value,
                        })
                      }
                      placeholder="What's in the background?"
                    />
                  </div>

                  <div className="md:col-span-2 bg-[#1A1A1F] p-6 rounded-xl">
                    <h4 className="text-white font-bold mb-4">
                      Special Elements
                    </h4>
                    <div className="space-y-4">
                      <TextField
                        fullWidth
                        label="Text Overlay"
                        value={basicPrompt.text}
                        onChange={(e) =>
                          setBasicPrompt({
                            ...basicPrompt,
                            text: e.target.value,
                          })
                        }
                        placeholder="Text to display on the thumbnail"
                      />
                      <TextField
                        fullWidth
                        label="Effects & Additions"
                        value={basicPrompt.effects}
                        onChange={(e) =>
                          setBasicPrompt({
                            ...basicPrompt,
                            effects: e.target.value,
                          })
                        }
                        placeholder="Any special effects or elements?"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="flex justify-between mt-4">
              <CustomButton onClick={handleBack}>Back</CustomButton>
              <CustomButton
                onClick={handleNext}
                disabled={
                  !prompt && (!basicPrompt.subject || !basicPrompt.action)
                }
              >
                Next
              </CustomButton>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="space-y-6 mt-8">
            <div>
              {useBaseModel ? (
                <div className="bg-gradient-to-r from-red-500/10 to-red-600/10 border border-red-500/20 rounded-lg p-4">
                  <div className="flex items-start space-x-4">
                    <div className="flex-grow">
                      <h3 className="text-white font-bold mb-2">
                        Realism Scale Not Available
                      </h3>
                      <p className="text-gray-400 text-sm">
                        Realism scale adjustment is only available when using a
                        custom model. This gives you more control over the
                        creative direction of your thumbnails.
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col space-y-2">
                  <ButtonGroup
                    variant="outlined"
                    aria-label="realism scale button group"
                    fullWidth
                    sx={{
                      height: "56px",
                      "& .MuiButton-root": {
                        color: "white",
                        borderColor: "#282828",
                        "&:hover": {
                          borderColor: "#484848",
                          backgroundColor: "rgba(255, 0, 0, 0.1)",
                        },
                        "&.Mui-selected": {
                          backgroundColor: "#FF0000",
                          "&:hover": {
                            backgroundColor: "#CC0000",
                          },
                        },
                      },
                    }}
                  >
                    {[2, 2.5, 3, 3.5].map((scale) => (
                      <Button
                        key={scale}
                        onClick={() => setRealismScale(scale)}
                        variant={
                          realismScale === scale ? "contained" : "outlined"
                        }
                        sx={{
                          flex: 1,
                          height: "100%",
                          backgroundColor:
                            realismScale === scale ? "#FF0000" : "transparent",
                        }}
                      >
                        {getModeLabel(scale)}
                      </Button>
                    ))}
                  </ButtonGroup>
                </div>
              )}
            </div>

            <div className="flex justify-between">
              <CustomButton onClick={handleBack}>Back</CustomButton>
              <CustomButton onClick={handleNext}>Next</CustomButton>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="space-y-6">
            {generating ? (
              <div className="flex flex-col items-center justify-center space-y-4">
                <CircularProgress size={20} sx={{ color: "#FF0000" }} />
                <p className="text-gray-400">Generating your thumbnails...</p>
              </div>
            ) : (
              <>
                {generatedThumbnails.length > 0 && (
                  <div className="mb-8">
                    <h4 className="text-base font-bold mb-4">
                      Latest Generation
                    </h4>
                    <Grid container spacing={3}>
                      {generations.map((generation, index) => (
                        <Grid item xs={12} md={6} key={generation.id}>
                          <div className="relative">
                            <div className="aspect-w-16 aspect-h-9">
                              <img
                                src={generation.outputUrl}
                                alt={`Generated Thumbnail ${index + 1}`}
                                className="object-cover w-full h-full rounded-lg"
                              />
                            </div>
                            <div className="absolute top-2 right-2 flex gap-2">
                              <IconButton
                                onClick={() => handleEditImage(generation)}
                                sx={{
                                  backgroundColor: "rgba(0,0,0,0.5)",
                                  "&:hover": {
                                    backgroundColor: "rgba(0,0,0,0.7)",
                                  },
                                }}
                              >
                                <IconEdit size={16} className="text-white" />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  handleDeleteGeneration(generation)
                                }
                                sx={{
                                  backgroundColor: "rgba(0,0,0,0.5)",
                                  "&:hover": {
                                    backgroundColor: "rgba(0,0,0,0.7)",
                                  },
                                }}
                              >
                                <IconTrash size={16} className="text-white" />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  handleFeedback(generation.id!, true)
                                }
                                sx={{
                                  backgroundColor: "rgba(0,0,0,0.5)",
                                  "&:hover": {
                                    backgroundColor: "rgba(0,0,0,0.7)",
                                  },
                                }}
                              >
                                <IconThumbUp
                                  size={16}
                                  className={
                                    generation.feedback?.isPositive === true
                                      ? "text-green-500"
                                      : "text-white opacity-50 hover:opacity-100"
                                  }
                                />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  handleNegativeFeedback(generation.id!)
                                }
                                sx={{
                                  backgroundColor: "rgba(0,0,0,0.5)",
                                  "&:hover": {
                                    backgroundColor: "rgba(0,0,0,0.7)",
                                  },
                                }}
                              >
                                <IconThumbDown
                                  size={16}
                                  className={
                                    generation.feedback?.isPositive === false
                                      ? "text-red-500"
                                      : "text-white opacity-50 hover:opacity-100"
                                  }
                                />
                              </IconButton>
                            </div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )}
                {generations.length === 0 && (
                  <div className="mt-8">
                    <h4 className="text-base font-custom font-bold mb-4">
                      Example Thumbnails
                    </h4>
                    <Grid container spacing={2}>
                      {[
                        {
                          src: "/images/generation-examples/1.png",
                          alt: "A dramatic scene of a massive tornado approaching a city skyline, with bold text reading 'STORM CHASER'.",
                        },
                        {
                          src: "/images/generation-examples/2.png",
                          alt: "A serene mountain lake reflecting snow-capped peaks at sunset, with text overlay 'HIDDEN GEMS'.",
                        },
                        {
                          src: "/images/generation-examples/3.png",
                          alt: "A sleek modern kitchen with cooking ingredients laid out, text reads 'EASY 5-MINUTE MEALS'.",
                        },
                        {
                          src: "/images/generation-examples/4.png",
                          alt: "An astronaut floating in space with Earth in the background, bold text 'SPACE MYSTERIES'.",
                        },
                        {
                          src: "/images/generation-examples/5.png",
                          alt: "A thumbnail shows Neil Armstrong's first step on the moon, Earth glowing in the background, and bold text reading 'One Giant Leap'.",
                        },
                        {
                          src: "/images/generation-examples/6.png",
                          alt: "Noah's Ark shows the massive ark on a hill under stormy gray skies, with pairs of animals walking toward it. Noah stands in the foreground as golden sunlight breaks through, and a rainbow starts to form, with bold text reading 'Noah's Ark' in the center.",
                        },
                        {
                          src: "/images/generation-examples/7.png",
                          alt: "Mount Fuji & a traditional Japanese torii gate at sunset, add bold text 'TRAVEL TIPS 101' at the top & 'JAPAN' in large red letters at the bottom",
                        },
                        {
                          src: "/images/generation-examples/8.png",
                          alt: "A man with a huge mansion and a Lamborghini behind him.",
                        },
                      ].map((image, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                          <Tooltip
                            title={
                              <div>
                                <p className="font-custom">{image.alt}</p>
                              </div>
                            }
                            arrow
                            placement="top"
                          >
                            <div className="aspect-w-16 aspect-h-9">
                              <img
                                src={image.src}
                                alt={image.alt}
                                className="object-cover w-full h-full rounded-lg"
                                loading={index > 0 ? "lazy" : undefined}
                              />
                            </div>
                          </Tooltip>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )}
              </>
            )}

            <CustomAlert
              open={error !== null}
              onClose={() => setError(null)}
              severity="error"
              action={
                error === "insufficient_credits" && (
                  <CustomButton
                    onClick={() => navigate("/app/thumb-genie/credits")}
                  >
                    Get Credits
                  </CustomButton>
                )
              }
            >
              {error === "insufficient_credits"
                ? "You don't have enough credits to generate thumbnails."
                : "An error occurred while generating the thumbnail."}
            </CustomAlert>
          </div>
        );
      // Add more cases for other steps
      default:
        return null;
    }
  };

  const formatBasicPrompt = (basicPrompt: {
    subject: string;
    action: string;
    style: string;
    background: string;
    effects: string;
    text: string;
  }): string => {
    const parts = [];

    if (basicPrompt.subject && basicPrompt.action) {
      parts.push(`${basicPrompt.subject} ${basicPrompt.action}`);
    }

    if (basicPrompt.style) {
      parts.push(`in ${basicPrompt.style} style`);
    }

    if (basicPrompt.background) {
      parts.push(`with a ${basicPrompt.background} background`);
    }

    if (basicPrompt.effects) {
      parts.push(`with ${basicPrompt.effects}`);
    }

    if (basicPrompt.text) {
      parts.push(`with text "${basicPrompt.text}"`);
    }

    return parts.filter(Boolean).join(", ");
  };

  const handleGenerate = async () => {
    if (!user) return;
    setGenerating(true);
    setError(null);
    setActiveStep(4);

    try {
      const functions = new Functions();
      const generationConfig = {
        userId: user.id,
        modelIdentifier: useBaseModel
          ? "black-forest-labs/flux-1.1-pro"
          : models.find((m) => m.id === selectedCustomModel)?.identifier || "",
        prompt: isAdvancedPrompt ? prompt : formatBasicPrompt(basicPrompt),
        image: selectedImage || undefined,
        guidanceScale: useBaseModel ? undefined : realismScale,
        skipPromptEnhancement: isAdvancedPrompt
          ? skipPromptEnhancement
          : prompt.length > 140,
      };

      const result = await functions.generateThumbnail(generationConfig);

      if (result.data.success && result.data.outputUrls.length > 0) {
        setGeneratedThumbnails(result.data.outputUrls);
        await fetchGenerations(selectedCustomModel);
      } else if (result.data.error === "INSUFFICIENT_CREDITS") {
        setError("insufficient_credits");
        setActiveStep(3);
      } else {
        setError("generation_error");
        setActiveStep(3);
      }
    } catch (error) {
      console.error("Error generating thumbnail:", error);
      setError("generation_error");
      setActiveStep(3);
    } finally {
      setGenerating(false);
    }
  };

  const fetchGenerations = async (modelId: string) => {
    if (user) {
      try {
        const generationDb = new GenerationDb();
        const modelIdentifier = useBaseModel
          ? "black-forest-labs/flux-1.1-pro"
          : models.find((m) => m.id === modelId)?.identifier;

        if (modelIdentifier) {
          const generationsQuery = query(
            generationDb.collection({ userId: user.id, generationId: "" }),
            where("modelIdentifier", "==", modelIdentifier),
            orderBy("createdAt", "desc")
          );
          const querySnapshot = await getDocs(generationsQuery);
          const generationsData = querySnapshot.docs.map((doc) =>
            Generation.fromFirestore(doc)
          );
          setGenerations(generationsData);
        }
      } catch (error) {
        console.error("Error fetching generations:", error);
      }
    }
  };

  const fetchAllGenerations = async () => {
    if (user) {
      try {
        const generationDb = new GenerationDb();
        const generationsQuery = query(
          generationDb.collection({ userId: user.id, generationId: "" }),
          orderBy("createdAt", "desc"),
          limit(40)
        );
        const querySnapshot = await getDocs(generationsQuery);
        const generationsData = querySnapshot.docs.map((doc) =>
          Generation.fromFirestore(doc)
        );
        setAllGenerations(generationsData);
      } catch (error) {
        console.error("Error fetching all generations:", error);
      }
    }
  };

  useEffect(() => {
    fetchAllGenerations();
  }, [user]);

  const handleEditImage = (generation: Generation) => {
    setSelectedEditImage(generation.outputUrl);
    setEditModalOpen(true);
  };

  const handleEditSubmit = async (data: { mask: string; prompt: string }) => {
    if (!user) return;
    setGenerating(true);
    setError(null);

    try {
      const functions = new Functions();
      const result = await functions.generateEditedThumbnail({
        userId: user.id,
        image: selectedEditImage!,
        mask: data.mask,
        prompt: data.prompt,
      });

      if (result.data.success && result.data.outputUrls.length > 0) {
        setGeneratedThumbnails(result.data.outputUrls);
        await fetchAllGenerations();
        setEditModalOpen(false);
      } else {
        setError("generation_error");
      }
    } catch (error) {
      console.error("Error generating edited thumbnail:", error);
      setError("generation_error");
    } finally {
      setGenerating(false);
    }
  };

  const handleFeedback = async (generationId: string, isPositive: boolean) => {
    if (!user) return;

    try {
      await updateDoc(
        new GenerationDb().doc({
          userId: user.id,
          generationId,
        }),
        {
          feedback: {
            isPositive,
          },
        }
      );
      await fetchGenerations(selectedCustomModel);
    } catch (error) {
      console.error("Error saving feedback:", error);
    }
  };

  const handleNegativeFeedback = (generationId: string) => {
    setSelectedGenerationId(generationId);
    setFeedbackDialogOpen(true);
  };

  const handleFeedbackSubmit = async (reason: string) => {
    if (!user || !selectedGenerationId) return;

    try {
      await updateDoc(
        new GenerationDb().doc({
          userId: user.id,
          generationId: selectedGenerationId,
        }),
        {
          feedback: {
            isPositive: false,
            reason,
          },
        }
      );
      await fetchGenerations(selectedCustomModel);
    } catch (error) {
      console.error("Error saving feedback:", error);
    }
  };

  const handleDeleteGeneration = async (generation: Generation) => {
    if (!user || !generation.id) return;

    try {
      // Delete from Firestore
      await deleteDoc(
        new GenerationDb().doc({
          userId: user.id,
          generationId: generation.id,
        })
      );

      // Delete from Storage
      if (generation.outputUrl) {
        const storage = getStorage();
        const imageRef = ref(storage, generation.outputUrl);
        await deleteObject(imageRef);
      }

      // Update UI state
      setAllGenerations((prev) => prev.filter((g) => g.id !== generation.id));
      setGenerations((prev) => prev.filter((g) => g.id !== generation.id));
    } catch (error) {
      console.error("Error deleting generation:", error);
    }
  };

  return (
    <>
      <div className="w-full min-h-screen bg-[#0B0B0F] text-white p-4 md:px-32 md:py-8 font-custom">
        <div className="flex justify-between items-center mb-4">
          <h4 className="text-base font-custom font-bold">
            Thumbnail Generator
          </h4>
        </div>
        <Divider
          sx={{
            borderColor: "#282828",
            borderBottomWidth: 2,
            marginBottom: 4,
          }}
        />

        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            "& .MuiStepIcon-root": {
              color: "#282828",
              width: "24px",
              height: "24px",
              "&.Mui-active, &.Mui-completed": {
                color: "#FF0000",
              },
              "&.Mui-disabled": {
                color: "rgba(40, 40, 40, 0.5)",
              },
            },
            "& .MuiStepIcon-text": {
              fill: "#FFFFFF",
              fontFamily: "'YouTubeSansRegular', sans-serif",
              fontSize: "12px",
            },
            "& .MuiStepLabel-label": {
              fontFamily: "'YouTubeSansRegular', sans-serif",
              fontSize: "12px",
              color: "rgba(255, 255, 255, 0.7)",
              "&.Mui-active": {
                color: "white",
                fontWeight: "bold",
              },
              "&.Mui-completed": {
                color: "rgba(255, 255, 255, 0.7)",
              },
              "&.Mui-disabled": {
                color: "rgba(255, 255, 255, 0.3)",
              },
            },
          }}
        >
          {steps.map((label, index) => (
            <Step key={label} disabled={useBaseModel && index === 2}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {renderStepContent(activeStep)}

        {/* Fixed Footer */}
        <div
          className={`fixed bottom-0 md:left-20 left-0 right-0 bg-[#0B0B0F] border-t border-[#282828] transition-all duration-300 ${
            isBottomBarVisible ? "translate-y-0" : "translate-y-full"
          }`}
        >
          {/* Toggle Button */}
          <button
            onClick={() => setIsBottomBarVisible(!isBottomBarVisible)}
            className="absolute -top-10 right-4 bg-[#0B0B0F] border border-[#282828] rounded-t-lg p-2 text-gray-400 hover:text-white transition-colors"
          >
            {isBottomBarVisible ? (
              <IconChevronDown size={20} stroke={1.5} />
            ) : (
              <IconChevronUp size={20} stroke={1.5} />
            )}
          </button>

          <div className="md:p-6 p-4">
            <div className="max-w-screen-2xl mx-auto flex gap-4 overflow-x-auto scrollbar-hide">
              {allGenerations.length > 0
                ? allGenerations.map((generation, index) => (
                    <div key={generation.id} className="relative">
                      <div className="absolute top-2 right-2 z-10 flex gap-2">
                        <IconButton
                          onClick={() => handleEditImage(generation)}
                          sx={{
                            backgroundColor: "rgba(0,0,0,0.5)",
                            "&:hover": { backgroundColor: "rgba(0,0,0,0.7)" },
                          }}
                        >
                          <IconEdit size={16} className="text-white" />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeleteGeneration(generation)}
                          sx={{
                            backgroundColor: "rgba(0,0,0,0.5)",
                            "&:hover": { backgroundColor: "rgba(0,0,0,0.7)" },
                          }}
                        >
                          <IconTrash size={16} className="text-white" />
                        </IconButton>
                      </div>
                      <Tooltip
                        title={
                          <div>
                            <p className="font-custom text-xs">
                              {generation.prompt}
                            </p>
                          </div>
                        }
                        arrow
                        placement="top"
                      >
                        <div className="flex-shrink-0 cursor-pointer hover:opacity-80 transition-opacity">
                          <div className="w-64 aspect-video relative">
                            <img
                              src={generation.outputUrl}
                              alt={`Generated Thumbnail ${index + 1}`}
                              className="w-full h-full object-cover rounded"
                            />
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  ))
                : // Example generations when no generations exist
                  [
                    {
                      src: "/images/generation-examples/1.png",
                      alt: "A dramatic scene of a massive tornado approaching a city skyline, with bold text reading 'STORM CHASER'.",
                    },
                    {
                      src: "/images/generation-examples/2.png",
                      alt: "A serene mountain lake reflecting snow-capped peaks at sunset, with text overlay 'HIDDEN GEMS'.",
                    },
                    {
                      src: "/images/generation-examples/3.png",
                      alt: "A sleek modern kitchen with cooking ingredients laid out, text reads 'EASY 5-MINUTE MEALS'.",
                    },
                    {
                      src: "/images/generation-examples/4.png",
                      alt: "An astronaut floating in space with Earth in the background, bold text 'SPACE MYSTERIES'.",
                    },
                    {
                      src: "/images/generation-examples/5.png",
                      alt: "A thumbnail shows Neil Armstrong's first step on the moon, Earth glowing in the background, and bold text reading 'One Giant Leap'.",
                    },
                    {
                      src: "/images/generation-examples/6.png",
                      alt: "Noah's Ark shows the massive ark on a hill under stormy gray skies, with pairs of animals walking toward it. Noah stands in the foreground as golden sunlight breaks through, and a rainbow starts to form, with bold text reading 'Noah's Ark' in the center.",
                    },
                    {
                      src: "/images/generation-examples/7.png",
                      alt: "Mount Fuji & a traditional Japanese torii gate at sunset, add bold text 'TRAVEL TIPS 101' at the top & 'JAPAN' in large red letters at the bottom",
                    },
                    {
                      src: "/images/generation-examples/8.png",
                      alt: "A man with a huge mansion and a Lamborghini behind him.",
                    },
                  ].map((example, index) => (
                    <div key={index} className="relative">
                      <Tooltip
                        title={
                          <div>
                            <p className="font-custom text-xs">{example.alt}</p>
                          </div>
                        }
                        arrow
                        placement="top"
                      >
                        <div className="flex-shrink-0 cursor-pointer hover:opacity-80 transition-opacity">
                          <div className="w-64 aspect-video relative">
                            <img
                              src={example.src}
                              alt={example.alt}
                              className="w-full h-full object-cover rounded"
                              loading={index > 0 ? "lazy" : undefined}
                            />
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  ))}
            </div>
          </div>
        </div>

        {/* Add padding to prevent content from being hidden behind the fixed footer */}
        <div className={`h-32 ${!isBottomBarVisible && "h-0"}`} />
      </div>

      <ImageEditModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        imageUrl={selectedEditImage || ""}
        onSubmit={handleEditSubmit}
      />
      <FeedbackDialog
        open={feedbackDialogOpen}
        onClose={() => setFeedbackDialogOpen(false)}
        onSubmit={handleFeedbackSubmit}
      />
      <OnboardingDialog open={showOnboarding} onClose={handleOnboardingClose} />
    </>
  );
};

export default PipelineScreen;
