import BaseDb from "./baseDb";
import toFirestore from "./toFirestore";
import { Transcription } from "../models/transcription";
import { FirestoreDataConverter } from "firebase/firestore";
import { omit } from "lodash";
import moment from "moment";

export default class TranscriptionDb extends BaseDb<
  Transcription,
  { videoId: string }
> {
  _path: string = "transcriptions/videoId";

  getConverter(): FirestoreDataConverter<Transcription> {
    return {
      fromFirestore: (snapshot, _options) => {
        const data = snapshot.data();
        return new Transcription({
          videoId: data.videoId,
          channelId: data.channelId,
          text: data.text,
          language: data.language,
          status: data.status,
          lastUpdated: moment(data.lastUpdated.toDate()),
          processingError: data.processingError,
          downloadId: data.downloadId,
          downloadUrl: data.downloadUrl,
        });
      },
      toFirestore: (transcription) => {
        return toFirestore(omit(transcription, ["id"]));
      },
    };
  }
}
