import React, { useState, useEffect } from "react";
import { youtube_v3 } from "googleapis";
import CustomButton from "../../../utils/customButton";
import Functions from "../../../../callable/functions";
import { Transcription } from "../../../../models/transcription";
import { Video } from "../../../../models/video";
import TranscriptionDb from "../../../../db/transcriptionDb";
import { onSnapshot } from "firebase/firestore";

type Props = {
  video: Video;
  ytvideo: youtube_v3.Schema$Video;
};

export default function TranscriptionComponent({ video, ytvideo }: Props) {
  const [loading, setLoading] = useState(false);
  const [transcription, setTranscription] = useState<Transcription | null>(
    null
  );

  // Reset state and set up new listener when video changes
  useEffect(() => {
    setTranscription(null); // Reset transcription when video changes

    if (!ytvideo.id) return;

    const unsubscribe = onSnapshot(
      new TranscriptionDb().doc({ videoId: ytvideo.id }),
      (doc) => {
        if (doc.exists()) {
          setTranscription(doc.data());
        } else {
          setTranscription(null);
        }
      }
    );

    return () => unsubscribe();
  }, [ytvideo.id]); // Depend on video ID instead of the whole video object

  const handleStartTranscription = async () => {
    setLoading(true);
    try {
      const result = await new Functions().startTranscription({
        videoId: ytvideo.id!,
        channelId: ytvideo.snippet!.channelId!,
      });

      if (result.data.status === "exists" && result.data.text) {
        // If transcription already exists, it will be updated through the Firestore listener
      } else if (result.data.status !== "pending") {
        console.error("Unexpected transcription status:", result.data.status);
      }
    } catch (error) {
      console.error("Error starting transcription:", error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusDisplay = () => {
    if (!transcription) return null;

    const statusMap = {
      pending: "⏳ Pending",
      processing: "🔄 Processing",
      completed: "✅ Completed",
      failed: "❌ Failed",
    };

    return statusMap[transcription.status] || transcription.status;
  };

  return (
    <div className="p-4">
      <h3 className="text-lg font-bold mb-2">Video Transcription</h3>

      {transcription ? (
        <div className="mb-4 space-y-4">
          <div className="flex items-center space-x-2">
            <span className="text-sm font-medium">Status:</span>
            <span className="text-sm">{getStatusDisplay()}</span>
          </div>

          {transcription.status === "completed" && transcription.text && (
            <div className="bg-gray-800 rounded-lg p-4">
              <p className="whitespace-pre-wrap text-sm">
                {transcription.text}
              </p>
            </div>
          )}

          {transcription.status === "failed" &&
            transcription.processingError && (
              <p className="text-error text-sm">
                {transcription.processingError}
              </p>
            )}
        </div>
      ) : (
        <p className="mb-4 text-gray-400">
          No transcription available for this video.
        </p>
      )}

      {(!transcription || transcription.status === "failed") && (
        <CustomButton
          onClick={handleStartTranscription}
          loading={loading}
          disabled={
            transcription?.status === "processing" ||
            transcription?.status === "pending"
          }
        >
          {transcription?.status === "failed"
            ? "Retry Transcription"
            : "Start Transcription"}
        </CustomButton>
      )}
    </div>
  );
}
