import { CircularProgress } from "@mui/material";
import { getDocs } from "firebase/firestore";
import { useContext, useState, useCallback, useEffect } from "react";
import { UserContext } from "../../../contexts/userContext";
import ChannelDb from "../../../db/channelDb";
import { Channel } from "../../../models/channel";
import ChannelTabs from "./channelTabs";
import TabPanel from "./tabPanel";
import VideoList from "./videoList";

export default function HomeScreen() {
  const user = useContext(UserContext);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [channelsLoading, setChannelsLoading] = useState(true);

  const fetchChannels = useCallback(
    async (retries = 3) => {
      if (user) {
        console.log("fetching channels for user:", user.id);
        const channelDb = new ChannelDb();
        const querySnapshot = await getDocs(
          channelDb.collection({ userId: user.id, channelId: "" })
        );
        const channelsData = querySnapshot.docs.map(
          (doc) => doc.data() as Channel
        );

        if (channelsData.length > 0 || retries === 0) {
          console.log("fetched channels:", channelsData);
          setChannels(channelsData);
          setChannelsLoading(false);
        } else {
          console.log(`retrying fetch channels, attempts left: ${retries}`);
          setTimeout(() => fetchChannels(retries - 1), 3000);
        }
      }
    },
    [user]
  );

  useEffect(() => {
    fetchChannels();
  }, [fetchChannels]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log("tab changed to index:", newValue);
    setSelectedTab(newValue);
  };

  return (
    <div className="full-w min-h-screen bg-[#0B0B0F] text-white p-2 md:px-16 md:py-8 font-custom">
      {channelsLoading ? (
        <div className="flex justify-center items-center min-h-screen">
          <CircularProgress color="inherit" size={20} />
        </div>
      ) : (
        <>
          <ChannelTabs
            channels={channels}
            selectedTab={selectedTab}
            handleTabChange={handleTabChange}
          />
          {channels.map((channel, index) => (
            <TabPanel
              key={channel.id}
              value={selectedTab}
              index={index}
              className="mt-4"
            >
              <VideoList channel={channel} />
            </TabPanel>
          ))}
        </>
      )}
    </div>
  );
}
