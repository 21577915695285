import React, { useState, useEffect } from "react";
import {
  IconCreditCard,
  IconCheck,
  IconMessageCircle,
} from "@tabler/icons-react";
import CustomButton from "./customButton";
import { Typography, Grid, Paper } from "@mui/material";
import {
  getFirestore,
  getDocs,
  collection,
  addDoc,
  onSnapshot,
} from "firebase/firestore";
import { map } from "lodash";
import { trackEvent } from "../../utils/analytics";

interface SubscriptionPlansProps {
  userId: string;
  showEnterprise?: boolean;
}

const PlanDetails = ({ items }: { items: string[] }) => (
  <div className="mt-4">
    {items.map((item, index) => (
      <div key={index} className="flex items-center space-x-2 mb-2">
        <IconCheck size={16} className="text-green-500" />
        <Typography variant="body2" className="text-gray-200">
          {item}
        </Typography>
      </div>
    ))}
  </div>
);

export const SubscriptionPlans: React.FC<SubscriptionPlansProps> = ({
  userId,
  showEnterprise = true,
}) => {
  const [purchasing, setPurchasing] = useState(false);
  const [packages, setPackages] = useState<
    {
      price: string;
      quantity: number;
      amount: number;
      active: boolean;
      type: string;
    }[]
  >([]);
  const [plansLoaded, setPlansLoaded] = useState(false);

  useEffect(() => {
    const fetchPackages = async () => {
      const db = getFirestore();
      const pricesCollections = [
        collection(db, "/products/prod_RkuSAWbJMLqS8C/prices"),
        collection(db, "/products/prod_RkuPIVzdXi267T/prices"),
        collection(db, "/products/prod_R34erVq6HwYlZy/prices"),
      ];

      const snapshots = await Promise.all(
        pricesCollections.map((collection) => getDocs(collection))
      );

      const allPackages = snapshots
        .flatMap((snapshot) =>
          map(snapshot.docs, (doc) => ({
            price: doc.id,
            quantity:
              doc.get("metadata.amount") ||
              doc.get("transform_quantity.divide_by"),
            amount: doc.get("unit_amount") / 100,
            active: doc.get("active"),
            type: doc.get("type"),
          }))
        )
        .filter((pkg) => pkg.active);

      setPackages(allPackages);
      setPlansLoaded(true);
    };
    fetchPackages();
  }, []);

  const handlePurchase = async (priceId: string, quantity: number) => {
    if (!userId) return;
    setPurchasing(true);
    trackEvent("purchase_credits", { priceId, quantity });

    const db = getFirestore();
    const sessionRef = await addDoc(
      collection(db, `users/${userId}/checkout_sessions`),
      {
        price: priceId,
        quantity: 1,
        payment_method_types: ["card"],
        mode: "subscription",
        billing_address_collection: "required",
        allow_promotion_codes: true,
        success_url: window.location.origin + "/app/thumb-genie/credits",
        cancel_url: window.location.href,
        customer_update: { name: "auto" },
        collect_tax_ids: true,
        metadata: {
          userId: userId,
          amount: quantity.toString(),
        },
      }
    );

    onSnapshot(sessionRef, (snap) => {
      const { error, url } = snap.data() as any;
      if (error) {
        setPurchasing(false);
        alert(`An error occurred: ${error.message}`);
      }
      if (url) {
        window.location.assign(url);
      }
    });
  };

  const subscriptionPackages = packages.filter(
    (pkg) => pkg.type === "recurring"
  );

  const renderPlanDetails = (pkg: any) => {
    if (pkg.type === "recurring") {
      if (pkg.quantity >= 200) {
        return [
          "3 Personalized AI Models",
          "200 Thumbnails (50 generations)",
          "50 Magical AI Edits",
        ];
      } else {
        return [
          "1 Personalized AI Model",
          "80 Thumbnails (20 generations)",
          "20 Magical AI Edits",
        ];
      }
    }
    return [];
  };

  return (
    <Grid container spacing={3}>
      {subscriptionPackages
        .slice()
        .reverse()
        .map((pkg) => (
          <Grid item xs={12} sm={6} md={4} key={pkg.price}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                borderRadius: "16px",
                background: "linear-gradient(135deg, #FF8A00 0%, #E52E71 100%)",
                color: "#FFFFFF",
                height: "100%",
              }}
            >
              <div className="flex flex-col h-full justify-between space-y-4">
                <div>
                  <Typography variant="h6" className="font-custom mb-1">
                    {pkg.quantity >= 200 ? "Premium" : "Pro"}
                  </Typography>
                  <Typography variant="h5" className="font-custom mb-1">
                    ${pkg.amount.toFixed(2)}/month
                  </Typography>
                  <PlanDetails items={renderPlanDetails(pkg)} />
                </div>
                <CustomButton
                  onClick={() => handlePurchase(pkg.price, pkg.quantity)}
                  disabled={purchasing}
                  startIcon={<IconCreditCard size={20} />}
                  variant="contained"
                  color="white"
                  fullWidth
                >
                  {purchasing ? "Processing..." : "Subscribe"}
                </CustomButton>
              </div>
            </Paper>
          </Grid>
        ))}

      {/* Only show Enterprise plan after other plans have loaded */}
      {plansLoaded && showEnterprise && (
        <Grid item xs={12} sm={6} md={4}>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              borderRadius: "16px",
              background: "linear-gradient(135deg, #000000 0%, #434343 100%)",
              color: "#FFFFFF",
              height: "100%",
            }}
          >
            <div className="flex flex-col h-full justify-between space-y-4">
              <div>
                <Typography variant="h6" className="font-custom mb-1">
                  Enterprise
                </Typography>
                <Typography variant="h5" className="font-custom mb-1">
                  Custom Pricing
                </Typography>
                <PlanDetails
                  items={[
                    "Customized AI Models",
                    "Customized Thumbnails",
                    "Priority Support",
                  ]}
                />
              </div>
              <CustomButton
                onClick={() =>
                  window.open(
                    "https://calendar.app.google/mU2hf2jVRsCs5dBNA",
                    "_blank"
                  )
                }
                startIcon={<IconMessageCircle size={20} />}
                variant="contained"
                color="white"
                fullWidth
              >
                Talk to Sales
              </CustomButton>
            </div>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default SubscriptionPlans;
