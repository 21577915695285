import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import CustomButton from "../../utils/customButton";

interface FeedbackDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (reason: string) => void;
}

const FeedbackDialog: React.FC<FeedbackDialogProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const [selectedReason, setSelectedReason] = React.useState("");

  const reasons = [
    "Doesn't match prompt",
    "Incorrect style/composition",
    "Doesn't follow the reference image",
    "Poor image quality",
    "Distorted features",
    "Unrealistic elements",
    "Other technical issues",
  ];

  const handleSubmit = () => {
    if (selectedReason) {
      onSubmit(selectedReason);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: "#0B0B0F",
          color: "white",
          borderRadius: "16px",
          minWidth: "400px",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontFamily: "'YouTubeSansRegular', sans-serif",
          fontSize: "1.125rem",
          paddingBottom: 2,
        }}
      >
        What went wrong?
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          value={selectedReason}
          onChange={(e) => setSelectedReason(e.target.value)}
        >
          {reasons.map((reason) => (
            <FormControlLabel
              key={reason}
              value={reason}
              control={
                <Radio
                  sx={{
                    color: "gray",
                    "&.Mui-checked": {
                      color: "#FF0000",
                    },
                  }}
                />
              }
              label={reason}
              sx={{
                "& .MuiFormControlLabel-label": {
                  color: "white",
                  fontFamily: "'YouTubeSansRegular', sans-serif",
                },
              }}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <CustomButton onClick={onClose}>Cancel</CustomButton>
        <CustomButton onClick={handleSubmit} disabled={!selectedReason}>
          Submit
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
