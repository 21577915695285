import { DocumentSnapshot } from "firebase/firestore";
import moment, { Moment } from "moment";

export interface ITranscription {
  videoId: string;
  channelId: string;
  text?: string;
  language?: string;
  status: "pending" | "processing" | "completed" | "failed";
  lastUpdated: Moment;
  processingError?: string;
  downloadId?: string;
  downloadUrl?: string;
}

export class Transcription implements ITranscription {
  public videoId!: string;
  public channelId!: string;
  public text?: string;
  public language?: string;
  public status!: "pending" | "processing" | "completed" | "failed";
  public lastUpdated!: Moment;
  public processingError?: string;
  public downloadId?: string;
  public downloadUrl?: string;

  constructor(param: ITranscription) {
    Object.assign(this, param);
  }

  public static fromFirestore(doc: DocumentSnapshot): Transcription {
    const data = doc.data();
    if (!data) throw new Error("document data is undefined");
    return new Transcription({
      videoId: data.videoId,
      channelId: data.channelId,
      text: data.text,
      language: data.language,
      status: data.status,
      lastUpdated: moment(data.lastUpdated.toDate()),
      processingError: data.processingError,
      downloadId: data.downloadId,
      downloadUrl: data.downloadUrl,
    });
  }

  public toFirestore(): Partial<ITranscription> {
    return {
      videoId: this.videoId,
      channelId: this.channelId,
      text: this.text,
      language: this.language,
      status: this.status,
      lastUpdated: this.lastUpdated,
      processingError: this.processingError,
      downloadId: this.downloadId,
      downloadUrl: this.downloadUrl,
    };
  }
}
