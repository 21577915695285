import React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { IconCalendar, IconArrowRight } from "@tabler/icons-react";
import CustomButton from "../utils/customButton";
import Footer from "../utils/footer";

export default function LandingPage() {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <div
      className="min-h-screen text-white flex flex-col items-center justify-center px-4 py-8 font-custom"
      style={{
        backgroundColor: "#0B0B0F",
        backgroundImage: "url(/images/background-pattern.png)",
        backgroundPosition: "top center",
      }}
    >
      <header className="w-full max-w-5xl flex justify-between items-center px-8 py-4 mx-auto">
        <img
          src="/images/logo.svg"
          alt="YouGenie Logo"
          className="h-8 cursor-pointer"
          onClick={handleLogoClick}
        />
      </header>
      <main className="text-center mt-8 max-w-5xl mx-auto px-4">
        <h1 className="text-4xl font-bold mb-6 md:text-6xl">YouGenie</h1>
        <p className="mb-6 text-gray-300">
          Discover our AI-powered suite of products designed to supercharge your
          YouTube channel.
        </p>
        <div className="flex flex-col md:flex-row justify-center mt-12 space-y-8 md:space-y-0 md:space-x-8">
          <div className="bg-[#1A1A1A] rounded-lg p-6 flex flex-col items-center max-w-md">
            <img
              src="/images/thumb-genie.png"
              alt="ThumbGenie"
              className="m-2"
            />
            <h2 className="text-2xl font-bold mb-2">ThumbGenie</h2>
            <p className="text-gray-300 mb-4">
              Create stunning thumbnails for your YouTube videos with our
              AI-powered Thumbnail Generator.
            </p>
            <CustomButton
              variant="contained"
              onClick={() => navigate("/thumb-genie")}
              color="primary"
            >
              Explore ThumbGenie
            </CustomButton>
          </div>
          <div className="bg-[#1A1A1A] rounded-lg p-6 flex flex-col items-center max-w-md">
            <img
              src="/images/insight-genie.png"
              alt="InsightGenie"
              className="m-2"
            />
            <h2 className="text-2xl font-bold mb-2">InsightGenie</h2>
            <p className="text-gray-300 mb-4">
              Unlock the full potential of your channel with our comprehensive
              analytics and insights platform.
            </p>
            <CustomButton
              variant="contained"
              onClick={() => navigate("/insight-genie")}
              color="primary"
            >
              Explore InsightGenie
            </CustomButton>
          </div>
        </div>
        <div className="mt-8 mb-4 flex flex-col items-center space-y-2">
          <a
            href="/tools"
            className="text-gray-300 hover:text-white flex items-center justify-center space-x-2"
          >
            <span>Browse Our Free Tools</span>
            <IconArrowRight size={16} />
          </a>
        </div>
      </main>
      <Footer />
    </div>
  );
}
