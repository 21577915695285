import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../contexts/userContext";
import {
  CircularProgress,
  Typography,
  Grid,
  Divider,
  Paper,
} from "@mui/material";
import { getDocs, collection, addDoc, onSnapshot } from "firebase/firestore";
import { map } from "lodash";
import {
  IconCoins,
  IconCreditCard,
  IconChevronDown,
  IconCheck,
  IconMessageCircle,
} from "@tabler/icons-react";
import { trackEvent } from "../../../utils/analytics";
import { getFirestore } from "firebase/firestore";
import CustomButton from "../../utils/customButton";
import SubscriptionPlans from "../../utils/subscriptionPlans";

const PlanDetails = ({ title, items }: { title: string; items: string[] }) => (
  <div className="mt-4">
    {items.map((item, index) => (
      <div key={index} className="flex items-center space-x-2 mb-2">
        <IconCheck size={16} className="text-green-500" />
        <Typography variant="body2" className="text-gray-200">
          {item}
        </Typography>
      </div>
    ))}
  </div>
);

export default function CreditsScreen() {
  const user = useContext(UserContext);
  const [purchasing, setPurchasing] = useState(false);
  const [showCredits, setShowCredits] = useState(false);
  const [packages, setPackages] = useState<
    {
      price: string;
      quantity: number;
      amount: number;
      active: boolean;
      type: string;
    }[]
  >([]);

  useEffect(() => {
    const fetchPackages = async () => {
      const db = getFirestore();

      // Fetch from both products
      const pricesCollections = [
        collection(db, "/products/prod_RkuSAWbJMLqS8C/prices"),
        collection(db, "/products/prod_RkuPIVzdXi267T/prices"),
        collection(db, "/products/prod_R34erVq6HwYlZy/prices"), // Keep existing product
      ];

      const snapshots = await Promise.all(
        pricesCollections.map((collection) => getDocs(collection))
      );

      // Combine all packages
      const allPackages = snapshots
        .flatMap((snapshot) =>
          map(snapshot.docs, (doc) => ({
            price: doc.id,
            quantity:
              doc.get("metadata.amount") ||
              doc.get("transform_quantity.divide_by"),
            amount: doc.get("unit_amount") / 100,
            active: doc.get("active"),
            type: doc.get("type"),
          }))
        )
        .filter((pkg) => pkg.active);

      setPackages(allPackages);
    };
    fetchPackages();
  }, []);

  const handlePurchase = async (priceId: string, quantity: number) => {
    if (!user) return;
    setPurchasing(true);
    trackEvent("purchase_credits", { priceId, quantity });

    const db = getFirestore();
    const sessionRef = await addDoc(
      collection(db, `users/${user.id}/checkout_sessions`),
      {
        price: priceId,
        quantity: 1,
        payment_method_types: ["card"],
        mode: "subscription",
        billing_address_collection: "required",
        allow_promotion_codes: true,
        success_url: window.location.origin + "/app/thumb-genie/credits",
        cancel_url: window.location.href,
        customer_update: { name: "auto" },
        collect_tax_ids: true,
        metadata: {
          userId: user.id,
          amount: quantity.toString(),
        },
      }
    );

    onSnapshot(sessionRef, (snap) => {
      const { error, url } = snap.data() as any;
      if (error) {
        setPurchasing(false);
        alert(`An error occurred: ${error.message}`);
      }
      if (url) {
        window.location.assign(url);
      }
    });
  };

  const subscriptionPackages = packages.filter(
    (pkg) => pkg.type === "recurring"
  );
  const creditPackages = packages.filter((pkg) => pkg.type !== "recurring");

  const renderPlanDetails = (pkg: any) => {
    if (pkg.type === "recurring") {
      if (pkg.quantity >= 200) {
        // Premium plan
        return [
          "3 Personalized AI Models",
          "200 Thumbnails (50 generations)",
          "50 Magical AI Edits",
        ];
      } else {
        // Pro plan
        return [
          "1 Personalized AI Model",
          "80 Thumbnails (20 generations)",
          "20 Magical AI Edits",
        ];
      }
    }
    return [];
  };

  if (!user) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <CircularProgress color="inherit" size={40} />
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen bg-[#0B0B0F] text-white p-2 md:px-32 md:py-8 font-custom">
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-base font-custom font-bold">ThumbGenie Plans</h4>
      </div>
      <Divider
        sx={{ borderColor: "#282828", borderBottomWidth: 2, marginBottom: 4 }}
      />

      {/* Balance Card */}
      <div className="bg-[#1E1E1E] px-6 py-4 rounded-lg flex items-center space-x-4 mb-6">
        <IconCoins size={30} className="text-[#FFD700]" />
        <div>
          <p className="text-sm text-gray-400">Balance</p>
          <p className="text-lg font-bold">
            {(user?.credits || 0).toLocaleString()} credits
          </p>
        </div>
      </div>

      {/* Subscription Packages */}
      {user?.id ? (
        <SubscriptionPlans userId={user.id} />
      ) : (
        <Typography variant="body2" color="error">
          Error loading user data
        </Typography>
      )}

      {/* Pay-as-you-go Link */}
      <div className="mt-8 text-center">
        <button
          onClick={() => setShowCredits(!showCredits)}
          className="text-gray-400 hover:text-white flex items-center justify-center mx-auto space-x-2 mb-8"
        >
          <span>Looking for pay-as-you-go credits?</span>
          <IconChevronDown
            size={20}
            className={`transform transition-transform ${
              showCredits ? "rotate-180" : ""
            }`}
          />
        </button>

        {/* Credit Packages */}
        {showCredits && (
          <Grid container spacing={3} className="mt-4">
            {creditPackages.map((pkg) => (
              <Grid item xs={12} sm={6} md={3} key={pkg.price}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 3,
                    borderRadius: "16px",
                    background: "#1E1E1E",
                    color: "#FFFFFF",
                    height: "100%",
                  }}
                >
                  <div className="flex flex-col h-full justify-between space-y-4">
                    <div>
                      <Typography variant="h6" className="font-custom mb-1">
                        {pkg.quantity.toLocaleString()} credits
                      </Typography>
                      <Typography variant="h5" className="font-custom mb-1">
                        ${pkg.amount.toFixed(2)}
                      </Typography>
                      <Typography
                        variant="body2"
                        className="font-custom text-gray-200"
                      >
                        ${(pkg.amount / pkg.quantity).toFixed(2)} per thumbnail
                      </Typography>
                    </div>
                    <CustomButton
                      onClick={() => handlePurchase(pkg.price, pkg.quantity)}
                      disabled={purchasing}
                      startIcon={<IconCreditCard size={20} />}
                      variant="contained"
                      color="white"
                      fullWidth
                    >
                      {purchasing ? "Processing..." : "Purchase"}
                    </CustomButton>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </div>
  );
}
