import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  CircularProgress,
  Divider,
  Tabs,
  Tab,
  Card,
  CardContent,
  Grid,
  Typography,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import {
  IconUsers,
  IconPhotoPlus,
  IconCpu,
  IconThumbUp,
  IconThumbDown,
  IconX,
  IconBrain,
  IconChartPie,
  IconInfoCircle,
} from "@tabler/icons-react";
import moment from "moment";
import { UserContext } from "../../../contexts/userContext";
import { useContext } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  Timestamp,
  collectionGroup,
  orderBy,
  limit,
} from "firebase/firestore";
import UserDb from "../../../db/userDb";
import ModelDb from "../../../db/modelDb";
import GenerationDb from "../../../db/generationDb";
import TrainingDb from "../../../db/trainingDb";
import { Generation } from "../../../models/generation";

// Time period options
const TIME_PERIODS = ["Last 7 days", "Last 30 days"];

// Base model identifier
const BASE_MODEL = "black-forest-labs/flux-1.1-pro";

interface FeedbackReason {
  reason: string;
  count: number;
}

const HqScreen: React.FC = () => {
  const user = useContext(UserContext);
  const db = getFirestore();

  // Basic metrics state
  const [userCount, setUserCount] = useState<number | null>(null);
  const [generationCount, setGenerationCount] = useState<number | null>(null);
  const [feedbackStats, setFeedbackStats] = useState<{
    positive: number;
    negative: number;
  } | null>(null);
  const [modelCount, setModelCount] = useState<number | null>(null);
  const [trainingCount, setTrainingCount] = useState<number | null>(null);
  const [topReasons, setTopReasons] = useState<FeedbackReason[]>([]);
  const [modelUsage, setModelUsage] = useState<{
    baseModel: number;
    customModels: number;
  }>({ baseModel: 0, customModels: 0 });
  const [userModelUsage, setUserModelUsage] = useState<{
    baseModelOnly: number;
    customModelsOnly: number;
    bothModels: number;
    inactive: number;
  }>({ baseModelOnly: 0, customModelsOnly: 0, bothModels: 0, inactive: 0 });

  // UI states
  const [loading, setLoading] = useState(true);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(0); // 0 = Last 7 days, 1 = Last 30 days

  // Get date range based on selected time period
  const getDateRange = useCallback(() => {
    const endDate = moment();
    const startDate = moment().subtract(
      selectedTimePeriod === 0 ? 7 : 30,
      "days"
    );
    return {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    };
  }, [selectedTimePeriod]);

  // Fetch metrics from Firestore
  const fetchMetrics = useCallback(async () => {
    setLoading(true);
    try {
      const { startDate, endDate } = getDateRange();
      const startTimestamp = Timestamp.fromDate(startDate);
      const endTimestamp = Timestamp.fromDate(endDate);

      // Count total users (not filtered by time period)
      const userDb = new UserDb();
      const usersQuery = query(userDb.collection({ userId: "" }));
      const usersSnapshot = await getDocs(usersQuery);
      setUserCount(usersSnapshot.size);

      // Count models created within the time period
      const modelsQuery = collectionGroup(db, "models");
      const modelsSnapshot = await getDocs(modelsQuery);

      // Filter models by date range
      let filteredModelCount = 0;
      modelsSnapshot.forEach((doc) => {
        const data = doc.data();
        const modelDate = data.createdAt?.toDate();
        if (modelDate && modelDate >= startDate && modelDate <= endDate) {
          filteredModelCount++;
        }
      });
      setModelCount(filteredModelCount);

      // Count trainings created within the time period
      const trainingsQuery = collectionGroup(db, "trainings");
      const trainingsSnapshot = await getDocs(trainingsQuery);

      // Filter trainings by date range
      let filteredTrainingCount = 0;
      trainingsSnapshot.forEach((doc) => {
        const data = doc.data();
        const trainingDate = data.createdAt?.toDate();
        if (
          trainingDate &&
          trainingDate >= startDate &&
          trainingDate <= endDate
        ) {
          filteredTrainingCount++;
        }
      });
      setTrainingCount(filteredTrainingCount);

      // Count generations and feedback using collectionGroup
      const generationsQuery = collectionGroup(db, "generations");
      const generationsSnapshot = await getDocs(generationsQuery);

      // Filter by date range and count
      let filteredGenerationsCount = 0;
      let positiveCount = 0;
      let negativeCount = 0;
      let baseModelCount = 0;
      let customModelCount = 0;
      const reasonsMap = new Map<string, number>();

      // Track user model usage
      const userModelMap = new Map<
        string,
        {
          usedBaseModel: boolean;
          usedCustomModel: boolean;
        }
      >();

      generationsSnapshot.forEach((doc) => {
        const data = doc.data();
        const generationDate = data.createdAt?.toDate();

        if (
          generationDate &&
          generationDate >= startDate &&
          generationDate <= endDate
        ) {
          filteredGenerationsCount++;

          // Get the user ID from the path
          const userId = doc.ref.path.split("/")[1];

          // Track base model vs custom model usage
          if (data.modelIdentifier === BASE_MODEL) {
            baseModelCount++;
            // Track per user
            const userStats = userModelMap.get(userId) || {
              usedBaseModel: false,
              usedCustomModel: false,
            };
            userStats.usedBaseModel = true;
            userModelMap.set(userId, userStats);
          } else {
            customModelCount++;
            // Track per user
            const userStats = userModelMap.get(userId) || {
              usedBaseModel: false,
              usedCustomModel: false,
            };
            userStats.usedCustomModel = true;
            userModelMap.set(userId, userStats);
          }

          if (data.feedback) {
            if (data.feedback.isPositive === true) {
              positiveCount++;
            } else if (data.feedback.isPositive === false) {
              negativeCount++;

              // Track reasons for negative feedback
              if (data.feedback.reason) {
                const reason = data.feedback.reason;
                reasonsMap.set(reason, (reasonsMap.get(reason) || 0) + 1);
              }
            }
          }
        }
      });

      // Calculate user model usage categories
      let baseModelOnlyUsers = 0;
      let customModelOnlyUsers = 0;
      let bothModelUsers = 0;
      let totalActiveUsers = 0;

      userModelMap.forEach((stats) => {
        totalActiveUsers++;
        if (stats.usedBaseModel && stats.usedCustomModel) {
          bothModelUsers++;
        } else if (stats.usedBaseModel) {
          baseModelOnlyUsers++;
        } else if (stats.usedCustomModel) {
          customModelOnlyUsers++;
        }
      });

      // Inactive users are total users minus those who created generations
      const inactiveUsers = usersSnapshot.size - totalActiveUsers;

      setGenerationCount(filteredGenerationsCount);
      setFeedbackStats({
        positive: positiveCount,
        negative: negativeCount,
      });
      setModelUsage({
        baseModel: baseModelCount,
        customModels: customModelCount,
      });
      setUserModelUsage({
        baseModelOnly: baseModelOnlyUsers,
        customModelsOnly: customModelOnlyUsers,
        bothModels: bothModelUsers,
        inactive: inactiveUsers,
      });

      // Sort reasons by count and get the top 5
      const sortedReasons = Array.from(reasonsMap.entries())
        .map(([reason, count]) => ({ reason, count }))
        .sort((a, b) => b.count - a.count)
        .slice(0, 5);

      setTopReasons(sortedReasons);
    } catch (error) {
      console.error("Error fetching metrics:", error);
    } finally {
      setLoading(false);
    }
  }, [db, getDateRange]);

  // Effect to fetch metrics on component mount and when date range changes
  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  // Handle time period change
  const handleTimePeriodChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setSelectedTimePeriod(newValue);
  };

  // Format numbers for display
  const formatNumber = (num: number | null): string => {
    if (num === null) return "-";

    if (num >= 1000) return `${(num / 1000).toFixed(1)}K`;
    return num.toString();
  };

  // Calculate feedback ratio
  const calculateFeedbackRatio = (): number => {
    if (!feedbackStats) return 0;
    const total = feedbackStats.positive + feedbackStats.negative;
    return total > 0 ? (feedbackStats.positive / total) * 100 : 0;
  };

  // Calculate base model vs custom model usage percentages
  const calculateModelUsagePercentage = (
    type: "baseModel" | "customModels"
  ): number => {
    const { baseModel, customModels } = modelUsage;
    const total = baseModel + customModels;
    if (total === 0) return 0;
    return type === "baseModel"
      ? (baseModel / total) * 100
      : (customModels / total) * 100;
  };

  // Calculate user model usage percentages
  const calculateUserModelPercentage = (
    type: "baseModelOnly" | "customModelsOnly" | "bothModels" | "inactive"
  ): number => {
    const { baseModelOnly, customModelsOnly, bothModels, inactive } =
      userModelUsage;
    const total = baseModelOnly + customModelsOnly + bothModels + inactive;
    if (total === 0) return 0;

    switch (type) {
      case "baseModelOnly":
        return (baseModelOnly / total) * 100;
      case "customModelsOnly":
        return (customModelsOnly / total) * 100;
      case "bothModels":
        return (bothModels / total) * 100;
      case "inactive":
        return (inactive / total) * 100;
      default:
        return 0;
    }
  };

  if (loading) {
    return (
      <div className="w-full min-h-screen bg-[#0B0B0F] text-white p-2 md:px-32 md:py-8 font-custom flex justify-center items-center">
        <CircularProgress color="inherit" size={40} />
      </div>
    );
  }

  return (
    <div className="flex-1 overflow-auto">
      <div className="max-w-screen-xl mx-auto p-2 md:px-16 md:py-8">
        <div className="mb-6 border-b border-[#282828] pb-4">
          <Typography
            variant="h6"
            fontWeight="bold"
            className="mb-2"
            color="white"
          >
            HQ Dashboard
          </Typography>
          <div className="flex justify-between items-center">
            <Typography variant="body2" color="white">
              Analytics and insights for ThumbGenie
            </Typography>
            <Tabs
              value={selectedTimePeriod}
              onChange={handleTimePeriodChange}
              sx={{
                minHeight: "32px",
                "& .MuiTabs-indicator": { backgroundColor: "#1DB954" },
                "& .MuiTab-root": { minHeight: "32px", padding: "0 16px" },
              }}
            >
              {TIME_PERIODS.map((period, index) => (
                <Tab
                  key={index}
                  label={period}
                  sx={{
                    color: "white",
                    fontSize: "12px",
                    "&.Mui-selected": { color: "#1DB954" },
                  }}
                />
              ))}
            </Tabs>
          </div>
        </div>

        <Grid container spacing={2} className="mb-4">
          <Grid item xs={6} sm={3}>
            <Card sx={{ backgroundColor: "#1E1E1E", color: "white" }}>
              <CardContent sx={{ padding: "12px !important" }}>
                <div className="flex justify-between items-start">
                  <div>
                    <div className="flex items-center">
                      <Typography
                        color="rgba(255, 255, 255, 0.6)"
                        fontSize={12}
                      >
                        Total Users
                      </Typography>
                      <Tooltip
                        title="Shows all users regardless of time period"
                        placement="top"
                      >
                        <IconInfoCircle
                          size={14}
                          className="ml-1 text-gray-400"
                        />
                      </Tooltip>
                    </div>
                    <Typography variant="h5" fontWeight="bold" className="mt-1">
                      {formatNumber(userCount)}
                    </Typography>
                  </div>
                  <IconUsers size={24} className="text-blue-500" />
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Card sx={{ backgroundColor: "#1E1E1E", color: "white" }}>
              <CardContent sx={{ padding: "12px !important" }}>
                <div className="flex justify-between items-start">
                  <div>
                    <Typography color="rgba(255, 255, 255, 0.6)" fontSize={12}>
                      New Trainings
                    </Typography>
                    <Typography variant="h5" fontWeight="bold" className="mt-1">
                      {formatNumber(trainingCount)}
                    </Typography>
                  </div>
                  <IconBrain size={24} className="text-purple-500" />
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Card sx={{ backgroundColor: "#1E1E1E", color: "white" }}>
              <CardContent sx={{ padding: "12px !important" }}>
                <div className="flex justify-between items-start">
                  <div>
                    <Typography color="rgba(255, 255, 255, 0.6)" fontSize={12}>
                      New Models
                    </Typography>
                    <Typography variant="h5" fontWeight="bold" className="mt-1">
                      {formatNumber(modelCount)}
                    </Typography>
                  </div>
                  <IconCpu size={24} className="text-yellow-500" />
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Card sx={{ backgroundColor: "#1E1E1E", color: "white" }}>
              <CardContent sx={{ padding: "12px !important" }}>
                <div className="flex justify-between items-start">
                  <div>
                    <Typography color="rgba(255, 255, 255, 0.6)" fontSize={12}>
                      New Generations
                    </Typography>
                    <Typography variant="h5" fontWeight="bold" className="mt-1">
                      {formatNumber(generationCount)}
                    </Typography>
                  </div>
                  <IconPhotoPlus size={24} className="text-green-500" />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Group model usage metrics */}
        <Grid container spacing={2} className="mb-4">
          <Grid item xs={12} md={6}>
            <Card sx={{ backgroundColor: "#1E1E1E", color: "white" }}>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  marginBottom={1}
                >
                  Model Usage Distribution
                </Typography>
                <div className="space-y-4">
                  <div>
                    <div className="flex justify-between mb-1">
                      <Typography
                        color="rgba(255, 255, 255, 0.6)"
                        fontSize={13}
                      >
                        Base Model:
                      </Typography>
                      <Typography fontSize={13}>
                        {formatNumber(modelUsage.baseModel)} (
                        {calculateModelUsagePercentage("baseModel").toFixed(0)}
                        %)
                      </Typography>
                    </div>
                    <LinearProgress
                      variant="determinate"
                      value={calculateModelUsagePercentage("baseModel")}
                      sx={{
                        height: 6,
                        borderRadius: 1,
                        backgroundColor: "rgba(255, 0, 0, 0.2)",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#FF0000",
                        },
                      }}
                    />
                  </div>
                  <div>
                    <div className="flex justify-between mb-1">
                      <Typography
                        color="rgba(255, 255, 255, 0.6)"
                        fontSize={13}
                      >
                        Custom Models:
                      </Typography>
                      <Typography fontSize={13}>
                        {formatNumber(modelUsage.customModels)} (
                        {calculateModelUsagePercentage("customModels").toFixed(
                          0
                        )}
                        %)
                      </Typography>
                    </div>
                    <LinearProgress
                      variant="determinate"
                      value={calculateModelUsagePercentage("customModels")}
                      sx={{
                        height: 6,
                        borderRadius: 1,
                        backgroundColor: "rgba(0, 255, 0, 0.2)",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#00FF00",
                        },
                      }}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ backgroundColor: "#1E1E1E", color: "white" }}>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  marginBottom={1}
                >
                  User Model Usage Patterns
                </Typography>
                <div className="space-y-3">
                  <div>
                    <div className="flex justify-between mb-1">
                      <Typography
                        color="rgba(255, 255, 255, 0.6)"
                        fontSize={13}
                      >
                        Only Base Model:
                      </Typography>
                      <Typography fontSize={13}>
                        {formatNumber(userModelUsage.baseModelOnly)} (
                        {calculateUserModelPercentage("baseModelOnly").toFixed(
                          0
                        )}
                        % of users)
                      </Typography>
                    </div>
                    <LinearProgress
                      variant="determinate"
                      value={calculateUserModelPercentage("baseModelOnly")}
                      sx={{
                        height: 6,
                        borderRadius: 1,
                        backgroundColor: "rgba(255, 0, 0, 0.2)",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#FF5555",
                        },
                      }}
                    />
                  </div>
                  <div>
                    <div className="flex justify-between mb-1">
                      <Typography
                        color="rgba(255, 255, 255, 0.6)"
                        fontSize={13}
                      >
                        Only Custom Models:
                      </Typography>
                      <Typography fontSize={13}>
                        {formatNumber(userModelUsage.customModelsOnly)} (
                        {calculateUserModelPercentage(
                          "customModelsOnly"
                        ).toFixed(0)}
                        % of users)
                      </Typography>
                    </div>
                    <LinearProgress
                      variant="determinate"
                      value={calculateUserModelPercentage("customModelsOnly")}
                      sx={{
                        height: 6,
                        borderRadius: 1,
                        backgroundColor: "rgba(0, 255, 0, 0.2)",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#55FF55",
                        },
                      }}
                    />
                  </div>
                  <div>
                    <div className="flex justify-between mb-1">
                      <Typography
                        color="rgba(255, 255, 255, 0.6)"
                        fontSize={13}
                      >
                        Both Model Types:
                      </Typography>
                      <Typography fontSize={13}>
                        {formatNumber(userModelUsage.bothModels)} (
                        {calculateUserModelPercentage("bothModels").toFixed(0)}%
                        of users)
                      </Typography>
                    </div>
                    <LinearProgress
                      variant="determinate"
                      value={calculateUserModelPercentage("bothModels")}
                      sx={{
                        height: 6,
                        borderRadius: 1,
                        backgroundColor: "rgba(255, 255, 0, 0.2)",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#FFFF00",
                        },
                      }}
                    />
                  </div>
                  <div>
                    <div className="flex justify-between mb-1">
                      <Typography
                        color="rgba(255, 255, 255, 0.6)"
                        fontSize={13}
                      >
                        No Generations in Period:
                      </Typography>
                      <Typography fontSize={13}>
                        {formatNumber(userModelUsage.inactive)} (
                        {calculateUserModelPercentage("inactive").toFixed(0)}%
                        of users)
                      </Typography>
                    </div>
                    <LinearProgress
                      variant="determinate"
                      value={calculateUserModelPercentage("inactive")}
                      sx={{
                        height: 6,
                        borderRadius: 1,
                        backgroundColor: "rgba(128, 128, 128, 0.2)",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#888888",
                        },
                      }}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Group feedback metrics */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card sx={{ backgroundColor: "#1E1E1E", color: "white" }}>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  marginBottom={1}
                >
                  Feedback Stats
                </Typography>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <Typography color="rgba(255, 255, 255, 0.6)" fontSize={13}>
                      Positive Ratings:
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      className="flex items-center"
                      fontSize={13}
                    >
                      <IconThumbUp size={14} className="text-green-500 mr-1" />
                      {formatNumber(feedbackStats?.positive || 0)}
                    </Typography>
                  </div>
                  <div className="flex justify-between">
                    <Typography color="rgba(255, 255, 255, 0.6)" fontSize={13}>
                      Negative Ratings:
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      className="flex items-center"
                      fontSize={13}
                    >
                      <IconThumbDown size={14} className="text-red-500 mr-1" />
                      {formatNumber(feedbackStats?.negative || 0)}
                    </Typography>
                  </div>
                  <div className="flex justify-between">
                    <Typography color="rgba(255, 255, 255, 0.6)" fontSize={13}>
                      Feedback Ratio:
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      className="flex items-center"
                      fontSize={13}
                    >
                      {calculateFeedbackRatio().toFixed(0)}% positive
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ backgroundColor: "#1E1E1E", color: "white" }}>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  marginBottom={1}
                >
                  Top Negative Feedback Reasons
                </Typography>
                {topReasons.length > 0 ? (
                  <div className="space-y-2">
                    {topReasons.map((reason, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center"
                      >
                        <Typography
                          color="rgba(255, 255, 255, 0.8)"
                          fontSize={13}
                          className="flex items-center"
                        >
                          <IconX size={10} className="text-red-500 mr-2" />
                          {reason.reason}
                        </Typography>
                        <Typography fontWeight="medium" fontSize={13}>
                          {reason.count}
                        </Typography>
                      </div>
                    ))}
                  </div>
                ) : (
                  <Typography color="rgba(255, 255, 255, 0.5)" fontSize={13}>
                    No negative feedback with reasons in this period
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default HqScreen;
