import React from "react";
import { IconCoins } from "@tabler/icons-react";
import { Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import SubscriptionPlans from "../utils/subscriptionPlans";

interface PaywallOverlayProps {
  currentCredits: number;
  onClose?: () => void;
  userId?: string;
}

const PaywallOverlay: React.FC<PaywallOverlayProps> = ({
  currentCredits,
  onClose,
  userId,
}) => {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: "#0B0B0F",
          color: "white",
          fontFamily: "'YouTubeSansRegular', sans-serif",
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontFamily: "'YouTubeSansBold', sans-serif",
          paddingBottom: 1,
        }}
      >
        Low Credits Alert
      </DialogTitle>
      <DialogContent>
        <div className="space-y-6 py-4">
          <Typography variant="body2" className="text-sx text-gray-300">
            Your credits are running low. Subscribe to a plan to continue
            generating amazing thumbnails!
          </Typography>

          <div className="bg-[#1E1E1E] px-6 py-4 rounded-lg flex items-center space-x-4">
            <IconCoins size={30} className="text-[#FFD700]" />
            <div>
              <p className="text-sm text-gray-400">Current Balance</p>
              <p className="text-lg font-bold">
                {currentCredits.toLocaleString()} credits
              </p>
            </div>
          </div>

          {userId ? (
            <SubscriptionPlans userId={userId} />
          ) : (
            <Typography variant="body2" color="error">
              Error loading user data
            </Typography>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PaywallOverlay;
